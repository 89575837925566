import Signin from './Signin'
import React, { useState } from 'react';
import Forgotpasswordform from './Forgotpasswordform';
import AdminSigninForm from './AdminSigninForm';

function AdminSignin() {
    const [view, setView] = useState('login'); // 'login' or 'forgotPassword'

    const handleLogin = (email, password) => {
      // Handle login logic (to be implemented)
    };
  
    const handleForgotPasswordClick = () => {
      setView('forgotPassword');
    };
  
    const handleBackToLogin = () => {
      setView('login');
    };

    const adminImage = require('../assets/Group 4.png');
  
  return (
    <>
    <Signin flImage = {adminImage}>
        <h2>Admin</h2>
        {view === 'login' && (
          <AdminSigninForm onLogin={handleLogin} onForgotPasswordClick={handleForgotPasswordClick} displayStatus={'none'} />
        )}
        {view === 'forgotPassword' && (
          <Forgotpasswordform onBackToLogin={handleBackToLogin} />
        )}
    </Signin>
    </>
  )
}

export default AdminSignin
