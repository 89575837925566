import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import "../styles/Invoice.css";
import "../styles/Currency.css";
import { useNavigate } from "react-router-dom";

const InvoiceTrash = () => {
  const [invoicesData, setInvoicesData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const getInvoicesData = async () => {
      try {
        const response = await fetch(
          "https://globalworker-backend.vercel.app/api/invoice/gettrashinvoices",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
          }
        );
        if (!response.ok) {
          console.log("Error while fetching the invoices data");
        }
        const data = await response.json();
        setInvoicesData(data);
      } catch (error) {
        console.log("Error while fetching the invoices data");
      }
    };
    getInvoicesData();
  }, []);

  useEffect(() => {
    console.log(invoicesData);
  }, [invoicesData]);

  const deleteInvoice = async (id) => {
    try {
        const conformationMessage = window.confirm('Are you sure you want to delete the invoice?');
      if(conformationMessage){
          const response = await fetch(
            `https://globalworker-backend.vercel.app/api/invoice/admin/deletetrashinvoice/${id}`,
            {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                "auth-token": localStorage.getItem('token'),
              },
            }
          );
          if (!response.ok) {
            throw new Error(`Error while deleting invoice ${response}`);
          }
          setInvoicesData((prevInvoicesData) =>
            prevInvoicesData.filter((invoiceData) => invoiceData.invoice._id !== id)
            );
          appendAlert("Invoice Deleted successfully.", "success")
          console.log('Invoice Deleted successfully')
      }
    } catch (error) {
        console.log(`Error while deleting invoice`)
        appendAlert("please try again later.", "danger")
    }
    window.scrollTo({
        top: 0,
        behavior: 'smooth' // Smooth scrolling
      });
  };

const alertPlaceholder = document.getElementById('liveAlertPlaceholder')
  const appendAlert = (message, type) => {
    const wrapper = document.createElement('div')
    wrapper.innerHTML = [
      `<div class="alert alert-${type} alert-dismissible" role="alert">`,
      `   <div>${message}</div>`,
      '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
      '</div>'
    ].join('')
  
    alertPlaceholder.append(wrapper)
  }

  return (
    <Layout setMWidth={"95%"} btnStatus={"none"}>
        <div id="liveAlertPlaceholder" style={{width: '100%'}}></div>
      <div className="row mb-3 row-1 table-rows table-rows-heading">
        <div className="col">
          <h6># Faktura</h6>
          {invoicesData.map((data, index) => (
            <div className="invoice-dynamic-div" key={index}>
              <p>{data?.invoice?.invoiceNumber}</p>
            </div>
          ))}
        </div>
        <div className="col">
          <h6>Medlem</h6>
          {invoicesData?.map((data, index) => (
            <div className="invoice-dynamic-div" key={index}>
              <h6>
                {data.userData.profileOption === "private"
                  ? data.userData.privateUserInfo.pvtFname
                  : data.userData.organizationUserInfo.organizationFname}
                {data.userData.profileOption === "private"
                  ? data.userData.privateUserInfo.pvtLname
                  : data.userData.organizationUserInfo.organizationLname}
              </h6>
              <h6>
                {data.userData.profileOption === "private"
                  ? data.userData.privateUserInfo.pvtUserEmail
                  : data.userData.organizationUserInfo.orgUserEmail}
              </h6>
            </div>
          ))}
        </div>
        <div className="col">
          <h6>Skickat datum</h6>
          {invoicesData.map((data, index) => (
            <div className="invoice-dynamic-div" key={index}>
              <p>{data.date}</p>
            </div>
          ))}
        </div>
        <div className="col">
          <h6>Kund</h6>
          {invoicesData.map((data, index) => (
            <div className="invoice-dynamic-div" key={index}>
              <p>
                {data.clientData.clientOption === "private"
                  ? data.clientData.privateClient.pvtFirstName
                  : data.clientData.organizationClient.orgFirstName}
              </p>
              <p>
                {data.clientData.clientOption === "private"
                  ? data.clientData.privateClient.pvtLastName
                  : data.clientData.organizationClient.orgLastName}
              </p>
              <p>
                {data.clientData.clientOption === "private"
                  ? data.clientData.privateClient.pvtRecipientEmail
                  : data.clientData.organizationClient.orgRecipientEmail}
              </p>
              <p>{data.clientData.customerNumber}</p>
            </div>
          ))}
        </div>
        <div className="col">
          <h6>Visa Faktura</h6>
          {invoicesData.map((data, index) => (
            <div className="invoice-dynamic-div" key={index}>
              <button
                onClick={() => {
                  navigate(
                    `/admin/generated-trash-invoice/${data.invoice._id}`
                  );
                }}
                className="invoiceView-btn"
                type="button"
              >
                visa faktura
              </button>
              <button
                onClick={()=>{deleteInvoice(data.invoice._id)}}
                className="invoiceView-btn mt-1 deleteBtn"
                type="button"
                style={{ padding: "10px 20px" }}
              >
                Radera
              </button>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default InvoiceTrash;
