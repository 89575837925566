import React, { useEffect, useState } from 'react';
import Layout from './Layout'
import '../styles/Invoice.css';
import '../styles/Currency.css'
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const MemberInvoices = () => {
    const {memberId} = useParams();
    const [invoicesData, setInvoicesData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [invoiceNumberInput, setInvoiceNumberInput] = useState("");
    const [emailInput, setEmailInput] = useState("");
    const [custNoInp, setCustNoInp] = useState("");
    const [statusInp, setStatusInp] = useState("");
    const navigate = useNavigate();
    useEffect(()=>{
      const getInvoicesData = async () =>{
        try {
          const response = await fetch(`https://globalworker-backend.vercel.app/api/admin/members/memberinvoices/${memberId}`,{
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
          })
          if(!response.ok){
            console.log("Error while fetching the invoices data");
          }
          const data = await response.json();
          setInvoicesData(data)
        } catch (error) {
          console.log("Error while fetching the invoices data");
        }
      }
      getInvoicesData()
    }, [])
  
    useEffect(()=>{
      console.log(invoicesData)
    }, [invoicesData])
    
  
    const invNoOnChange = (e) =>{
      setInvoiceNumberInput(e.target.value)
    }
    const emailInputOnChange = (e) =>{
      setEmailInput(e.target.value)
    }
    const custNoOnChange = (e) =>{
      setCustNoInp(e.target.value)
    }
    const statusOnChange = (e) =>{
      setStatusInp(e.target.value)
      console.log(e.target.value)
    }
  
    function stringToBoolean(str) {
      // Convert the string to lower case for case-insensitive comparison
      const lowerCaseStr = str.toLowerCase();
      
      // Return true if the string is "true", "yes", "1", or "on"
      if (lowerCaseStr === "true" || lowerCaseStr === "yes" || lowerCaseStr === "1" || lowerCaseStr === "on") {
        return true;
      }
      
      // Return false if the string is "false", "no", "0", or "off"
      if (lowerCaseStr === "false" || lowerCaseStr === "no" || lowerCaseStr === "0" || lowerCaseStr === "off") {
        return false;
      }
      
      // Return null if the string doesn't represent a boolean value
      return null;
    }
  
    const handleSearch = () => {
      let filtered = invoicesData;
      console.log(filtered)
  
    if (invoiceNumberInput !== "") {
      filtered = filtered.filter(data =>
        data.invoice?.invoiceNumber === parseInt(invoiceNumberInput)
      );
    }
  
    if (emailInput !== "") {
      filtered = filtered.filter(data =>
        (data?.userData?.privateUserInfo?.pvtUserEmail === emailInput || data?.userData?.organizationUserInfo?.orgUserEmail === emailInput)
      );
    }
  
    if (custNoInp !== "") {
      filtered = filtered.filter(data =>
        data.clientData.customerNumber === parseInt(custNoInp)
      );
      console.log(filtered);
    }
  
    if (statusInp !== "") {
      const bool1 = stringToBoolean(statusInp);
      filtered = filtered.filter(data =>
        data.sentToCustomerStatus === bool1
      );
    }
  
    if (invoiceNumberInput === "" && emailInput === "" && custNoInp === "" && statusInp === "") {
      filtered = invoicesData;
    }
  
    setFilteredData(filtered);
    console.log(filtered);
    };
  
    return (
      <>
      <Layout setMWidth={"95%"} btnStatus={'none'}>
        <form className="row g-4 container invoice-search-form mt-2">
    <div className="invoice-form-col col-sm-3">
      <label htmlFor="invoice-no-input" className="form-label invoice-labels">Faktura #</label>
      <input type="number" className="form-input-invoice form-control" id="invoice-no-input" onChange={invNoOnChange} value={invoiceNumberInput}/>
    </div>
    <div className="invoice-form-col col-sm-3">
      <label htmlFor="invoice-member-email" className="form-label invoice-labels">E-post medlem</label>
      <input type="email" className="form-input-invoice form-control" id="invoice-member-email" onChange={emailInputOnChange} value={emailInput}/>
    </div>
    <div className="invoice-form-col col-sm-2">
      <label htmlFor="customer-no-input" className="form-label invoice-labels">Kund #</label>
      <input type="number" className="form-input-invoice form-control" id="customer-no-input" onChange={custNoOnChange} value={custNoInp}/>
    </div>
    <div className="invoice-form-col col-sm-3">
      <label className="form-label invoice-labels">Status</label>
      <select onChange={statusOnChange} value={statusInp} style={{display: 'flex', padding: '7px'}}>
        <option value="">Skickat till kund status</option>
        <option value={true}>Sann</option>
        <option value={false}>Falsk</option>
      </select>
      </div>
    <div className='sokBtn-outerDiv'>
       <div className='sokBtn-innerDiv'>
        <button type='button' className='sokBtn btn btn-primary' onClick={handleSearch}>Sök</button>
        </div>
    </div>
    
    </form>
    <hr style={{ width: '89%' }} />
    
    <div className="row mb-3 table-rows table-rows-heading">
    {filteredData.length === 0 ? (
      <>
        <div className="col">
          <h6># Faktura</h6>
          {invoicesData?.map((data, index) => (
            <div className='invoice-dynamic-div' key={index}>
              <p>{data?.invoice?.invoiceNumber}</p>
            </div>
          ))}
        </div>
        <div className="col">
          <h6>Medlem</h6>
          {invoicesData?.map((data, index) => (
            <div className='invoice-dynamic-div' key={index}>
              <h6>{data.userData.profileOption === 'private' ? data.userData.privateUserInfo.pvtFname : data.userData.organizationUserInfo.organizationFname} {data.userData.profileOption === 'private' ? data.userData.privateUserInfo.pvtLname : data.userData.organizationUserInfo.organizationLname} 
              </h6>
              <h6>{data.userData.profileOption === 'private' ? data.userData.privateUserInfo.pvtUserEmail : data.userData.organizationUserInfo.organizationUserEmail}</h6>
            </div>
          ))}
        </div>
        <div className="col">
          <h6>Skickat datum</h6>
          {invoicesData?.map((data, index) => (
            <div className='invoice-dynamic-div' key={index}>
              <p>{data.date}</p>
            </div>
          ))}
        </div>
        <div className="col">
          <h6>Kund</h6>
          {invoicesData?.map((data, index) => (
            <div className='invoice-dynamic-div' key={index}>
              <p>{data.clientData.clientOption === 'private' ? data.clientData.privateClient.pvtFirstName : data.clientData.organizationClient.orgFirstName}</p>
              <p>{data.clientData.clientOption === 'private' ? data.clientData.privateClient.pvtLastName : data.clientData.organizationClient.orgLastName}</p>
              <p>{data.clientData.clientOption === 'private' ? data.clientData.privateClient.pvtRecipientEmail : data.clientData.organizationClient.orgRecipientEmail}</p>
              <p>{data.clientData.customerNumber}</p>
            </div>
          ))}
        </div>
        <div className="col">
          <h6>Status</h6>
          {invoicesData?.map((data, index) => (
            <div className='invoice-dynamic-div' key={index}>
              <p>{data.sentToCustomerStatus ? "Skickat till kund" : "Pendlar"}</p>
            </div>
          ))}
        </div>
        <div className="col">
          <h6>Visa Faktura</h6>
          {invoicesData?.map((data, index) => (
            <div className='invoice-dynamic-div' key={index}>
              <button onClick={() => navigate(`/invoice/invoice-info/${data.invoice._id}`)} className='invoiceView-btn' type='button'>visa faktura</button>
            </div>
          ))}
        </div>
      </>
    ) : <>
    <div className="col">
      <h6># Faktura</h6>
      {filteredData.map((data, index) => (
        <div className='invoice-dynamic-div' key={index}>
          <p>{data?.invoice?.invoiceNumber}</p>
        </div>
      ))}
    </div>
    <div className="col">
      <h6>Medlem</h6>
      {filteredData.map((data, index) => (
        <div className='invoice-dynamic-div' key={index}>
          <h6>{data.userData.profileOption === 'private' ? data.userData.privateUserInfo.pvtFname : data.userData.organizationUserInfo.orgFname}
            {data.userData.profileOption === 'private' ? data.userData.privateUserInfo.pvtLname : data.userData.organizationUserInfo.orgLname}
          </h6>
          <h6>{data.userData.profileOption === 'private' ? data.userData.privateUserInfo.pvtUserEmail : data.userData.organizationUserInfo.orgUserEmail}</h6>
        </div>
      ))}
    </div>
    <div className="col">
      <h6>Skickat datum</h6>
      {filteredData.map((data, index) => (
        <div className='invoice-dynamic-div' key={index}>
          <p>{data.date}</p>
        </div>
      ))}
    </div>
    <div className="col">
      <h6>Kund</h6>
      {filteredData.map((data, index) => (
        <div className='invoice-dynamic-div' key={index}>
          <p>{data.clientData.clientOption === 'private' ? data.clientData.privateClient.pvtFirstName : data.clientData.organizationClient.orgFirstName}</p>
          <p>{data.clientData.clientOption === 'private' ? data.clientData.privateClient.pvtLastName : data.clientData.organizationClient.orgLastName}</p>
          <p>{data.clientData.clientOption === 'private' ? data.clientData.privateClient.pvtRecipientEmail : data.clientData.organizationClient.orgRecipientEmail}</p>
          <p>{data.clientData.customerNumber}</p>
        </div>
      ))}
    </div>
    <div className="col">
      <h6>Status</h6>
      {filteredData.map((data, index) => (
        <div className='invoice-dynamic-div' key={index}>
          <p>{data.sentToCustomerStatus ? "Skickat till kund" : "Pendlar"}</p>
        </div>
      ))}
    </div>
    <div className="col">
      <h6>Visa Faktura</h6>
      {filteredData.map((data, index) => (
        <div className='invoice-dynamic-div' key={index}>
          <button onClick={() => navigate(`/invoice/invoice-info/${data.invoice._id}`)} className='invoiceView-btn' type='button'>visa faktura</button>
        </div>
      ))}
    </div>
  </>}
      </div>
      
      </Layout>
      </>
    );
}

export default MemberInvoices
