import React, { useEffect } from "react";
import { useState } from "react";
import "../styles/ClientInfo.css";
function ClientInfo({ invoiceData }) {
  const [selectedOption, setSelectedOption] = useState(invoiceData?.clientData?.clientOption || "")
  const [organizationClient, setOrganizationClient] = useState({
    orgId: invoiceData?.clientData?.organizationClient?.orgIdNumber || "",
    orgVAT: invoiceData.clientData?.organizationClient?.orgVATNumber || "",
    orgName: invoiceData?.clientData?.organizationClient?.orgFirstName || "",
    recipientEmail: invoiceData?.clientData?.organizationClient?.orgRecipientEmail || "",
    referenceName: invoiceData?.clientData?.organizationClient?.orgReferenceName || "",
    contact: invoiceData?.clientData?.organizationClient?.orgContactNumber || "",
    currencyCode: invoiceData?.clientData?.organizationClient?.orgCurrencyCode || "",
    poBox: invoiceData?.clientData?.organizationClient?.orgStreet || "",
    postalCode: invoiceData?.clientData?.organizationClient?.orgPostalCode || "",
    city: invoiceData?.clientData?.organizationClient?.orgCity || "",
    country: invoiceData?.clientData?.organizationClient?.orgCountry || "",
  });
  const [privateClient, setPrivateClient] = useState({
    pvtId: invoiceData?.clientData?.privateClient?.pvtIdNumber || "",
    pvtFName: invoiceData?.clientData?.privateClient?.pvtFirstName || "",
    pvtLName: invoiceData?.clientData?.privateClient?.pvtLastName || "",
    recipientEmail: invoiceData?.clientData?.privateClient?.pvtRecipientEmail || "",
    referenceName: invoiceData?.clientData?.privateClient?.pvtReferenceName || "",
    contact: invoiceData?.clientData?.privateClient?.pvtContactNumber || "",
    currencyCode: invoiceData?.clientData?.privateClient?.pvtCurrencyCode || "",
    street: invoiceData?.clientData?.privateClient?.pvtStreet || "",
    postalCode: invoiceData?.clientData?.privateClient?.pvtPostalCode || "",
    city: invoiceData?.clientData?.privateClient?.pvtCity || "",
    country: invoiceData?.clientData?.privateClient?.pvtCountry || "",
  });

  
  
  return (
    <>
      <div className="client-info-main-container">
        <div className="client-info-radio-container">
          <div className="Privatpersons-container">
            <h6 className="Privatpersons-heading">Privatpersons eller</h6>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                value='private'
                checked={selectedOption === "private"}
                disabled
              />
              <label className="form-check-label" htmlFor="flexRadioDefault1">
                Privatkund
              </label>
            </div>
          </div>

          <div className="företags-kund-container">
            <h6 className="företags-kund-heading">företags kund</h6>
            <div className="form-check">
              <input
                disabled
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                value='organization'
                checked={selectedOption === "organization"}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                Företagskund
              </label>
            </div>
          </div>
        </div>
        {selectedOption === 'organization' && <><div className="row-1 company-info-row1">
          <div className="col">
            <form className="form-div row g-3">
              <div className="">
                <label htmlFor="org-id" className="label form-label">
                  Org. Nr: / ID. Nr:
                </label>
                <input
                  disabled
                  type="text"
                  className="form-input form-control"
                  id="org-id"
                  value={organizationClient.orgId}
                />
              </div>

              <div className="">
                <label htmlFor="vat" className="label form-label">
                  VAT-nummer
                </label>
                <input
                  disabled
                  type="text"
                  className="form-input form-control"
                  id="vat"
                  value={organizationClient.orgVAT || ""}
                />
              </div>
              <div className="">
                <label htmlFor="company-name" className="label form-label">
                  Företagets namn
                </label>
                <input
                  disabled
                  type="text"
                  className="form-input form-control"
                  id="company-name"
                  value={organizationClient.orgName}
                />
              </div>
              <div className="">
                <label
                  htmlFor="invoice-recipient-email"
                  className=" label form-label"
                >
                  Fakturamottagare e-post (till den som har hand om betalningen
                  av fakturan)
                </label>
                <input
                  disabled
                  type="email"
                  className="form-input form-control"
                  id="invoice-recipient-email"
                  value={organizationClient.recipientEmail}
                />
              </div>
              <div className="">
                <label htmlFor="reference-name" className=" label form-label">
                  Referensens namn (den person som har beställt, eller känner
                  till beställningen av tjänsten)
                </label>
                <input
                  disabled
                  type="text"
                  className="form-input form-control"
                  id="reference-name"
                  value={organizationClient.referenceName}
                />
              </div>
            </form>
          </div>
          <div className="col">
            <form className="form-div row g-3">
            <div className="">
              <label
                htmlFor="orgContactNumber"
                className=" label form-label"
              >
                Kontaktens / Beställarens mobil nummer
              </label>
              <input
                type="text"
                className="form-input form-control pvt-input-field"
                id="orgContactNumber"
                value={organizationClient.contact}
                disabled
              />
            </div>
              <div className="">
                <label
                  htmlFor="invoice-currency-code"
                  className="label form-label"
                >
                  Valutakod
                </label>
                <input
                  disabled
                  type="text"
                  className="form-input form-control"
                  id="invoice-currency-code"
                  value={organizationClient.currencyCode}
                />
              </div>

              <div className="">
                <label htmlFor="street-box" className="label form-label">
                  Gata / Box
                </label>
                <input
                  disabled
                  type="text"
                  className="form-input form-control"
                  id="street-box"
                  value={organizationClient.poBox}
                />
              </div>
              <div className="">
                <label htmlFor="country-code" className="label form-label">
                  Landskod / Postnummer (tex. SE-441 96)
                </label>
                <input
                  disabled
                  type="text"
                  className="form-input form-control"
                  id="country-code"
                  value={organizationClient.postalCode}
                />
              </div>
              <div className="">
                <label htmlFor="invoice-city" className=" label form-label">
                  Stad / Plats
                </label>
                <input
                  disabled
                  type="text"
                  className="form-input form-control"
                  id="invoice-city"
                  value={organizationClient.city}
                />
              </div>
              <div className="">
                <label htmlFor="invoice-city" className=" label form-label">
                  Country
                </label>
                <input
                  disabled
                  type="text"
                  className="form-input form-control"
                  id="invoice-Country"
                  value={organizationClient.country}
                />
              </div>
            </form>
          </div>
        </div></>}
        {selectedOption === 'private' && <>
        <div className="row row-1 company-info-row1">
        <div className="col">
          <div className="form-div row g-3">
            <div className="">
              <label htmlFor="pvtIdNumber" className="label form-label">
                Personnummer / ID: Nummer
              </label>
              <input
                type="text"
                className="form-input form-control pvt-input-field"
                id="pvtIdNumber"
                value={privateClient.pvtId}
                disabled
              />
            </div>

            <div className="">
              <label htmlFor="pvtFirstName" className="label form-label">
                Förnamn
              </label>
              <input
                type="text"
                className="form-input form-control pvt-input-field"
                id="pvtFirstName"
                value={privateClient.pvtFName}
                disabled
              />
            </div>
            <div className="">
              <label htmlFor="pvtLastName" className="label form-label">
                Efternamn
              </label>
              <input
                type="text"
                className="form-input form-control pvt-input-field"
                id="pvtLastName"
                value={privateClient.pvtLName}
                disabled
              />
            </div>
            <div className="">
              <label
                htmlFor="pvtRecipientEmail"
                className=" label form-label"
              >
                Fakturamottagare e-post (till den som har hand om betalningen av
                fakturan)
              </label>
              <input
                type="email"
                className="form-input form-control pvt-input-field"
                id="pvtRecipientEmail"
                value={privateClient.recipientEmail}
                disabled
              />
            </div>
            <div className="">
              <label
                htmlFor="pvtReferenceName"
                className=" label form-label"
              >
                Referensens namn (den person som har beställt, eller känner till
                beställningen av tjänsten)
              </label>
              <input
                type="text"
                className="form-input form-control pvt-input-field pvt-input-field"
                id="pvtReferenceName"
                value={privateClient.referenceName}
                disabled
              />
            </div>
            <div className="">
              <label
                htmlFor="pvtContactNumber"
                className=" label form-label"
              >
                Kontaktens / Beställarens mobil nummer
              </label>
              <input
                type="text"
                className="form-input form-control pvt-input-field"
                id="pvtContactNumber"
                value={privateClient.contact}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="col">
          <div className="form-div row g-3">
            <div className="">
              <label className="label form-label">Valutakod</label>
              <input
              id="pvtCurrencyCode"
              type="text"
                className="form-select form-control wise-dropdown"
                value={privateClient.currencyCode}
                disabled
              />
            </div>

            <h6 className="client-address-heading mt-4">
            Privatkund adress
            </h6>
            <div className="">
              <label htmlFor="pvtStreet" className="label form-label">
                Gata / Box
              </label>
              <input
                type="text"
                className="form-input form-control pvt-input-field"
                id="pvtStreet"
                value={privateClient.street}
                disabled
              />
            </div>
            <div className="">
              <label
                htmlFor="pvtPostalCode"
                className=" label form-label"
              >
                Landskod / Postnummer (tex. SE-441 96)
              </label>
              <input
                type="text"
                className="form-input form-control pvt-input-field"
                id="pvtPostalCode"
                value={privateClient.postalCode}
                disabled
              />
            </div>
            <div className="">
              <label htmlFor="pvtCity" className=" label form-label">
                Stad / Plats
              </label>
              <input
                type="text"
                className="form-input form-control pvt-input-field pvt-input-field"
                id="pvtCity"
                value={privateClient.city}
                disabled
              />
            </div>
            <div className=" ">
              <label htmlFor="pvtCountry" className=" label form-label">
                Land
              </label>
              <input
              type="text"
                id="pvtCountry"
                className="form-select form-control wise-dropdown"
                value={privateClient.country}
                disabled
                />
            </div>
          </div>
        </div>
      </div>
        </>}
      </div>
    </>
  );
}

export default ClientInfo;
