import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Dashboard.css';
import '../styles/Navbar.css';
import AdminNavbar from './AdminNavbar';

const Dashboard = () => {
    return (
        <>
        <AdminNavbar/>
        <div className="container text-center mt-5" style={{ paddingTop: '60px' }}>
            {/* <h1 id="dashboard-heading" className="">Dashboard</h1> */}
            <div className="row">
                <div className="col">
                <Link className='btn-heading' to={'/invoice'}>
                    <button className="dashboard-btn btn btn-primary mt-4 mb-5" >
                        Nya fakturor
                        <img id="invoice-img" src={require('../assets/Invoice icon (1).png')} alt="Image" />
                    </button>
                    </Link>
                    <Link className='btn-heading' to={'/invoicetrash'}>
                    <button className="dashboard-btn btn btn-primary">
                        Raderade fakturor
                        <img src={require('../assets/Delete Icon.png')} alt="Image" />
                    </button>
                    </Link>
                </div>
                <div className="col">
                <Link className='btn-heading' to={'/members'}>
                    <button className="dashboard-btn btn btn-primary mt-4 mb-5">
                        Medlem
                        <img id="recruit-img" src={require('../assets/Recruit icon.png')} alt="Image" />
                    </button>
                    </Link>
                    <Link className='btn-heading' to={'/account-information'}>
                    <button className="dashboard-btn btn btn-primary">
                        Inställningar
                        <img src={require('../assets/Settings Icon.png')} alt="Image" />
                    </button>
                    </Link>
                </div>
                <div className="col">
                    <Link className='btn-heading' to="/country-list">
                    <button className="dashboard-btn btn btn-primary mt-4 mb-5">
                        Land
                        <img id="land-img" src={require('../assets/Country Icon.png')} alt="Image" />
                    </button>
                    </Link>
                    <Link className='btn-heading' to="/currency">
                        <button type='button' className="dashboard-btn btn btn-primary ">
                            Valuta
                        <img src={require('../assets/Currency Icon.png')} alt="Image" />
                        </button>
                    </Link>
                </div>
            <div className="dash-img">
                <img src={require('../assets/Clip_path_group-new.png')} alt="" />
            </div>
            </div>
        </div>
        
        </>
    );
};

export default Dashboard;
