import React, {useState, useEffect} from 'react'
import Layout from './Layout'
import '../styles/InvoiceView.css'
import InvoiceInfo from './InvoiceInfo'
import ClientInfo from './ClientInfo'
import PrivateCustomer from './PrivateCustomer'
import PaymentInfo from './PaymentInfo'
import { useParams } from 'react-router-dom';
function InvoiceView() {
  const {invoiceId} = useParams();
  const [invoiceData, setInvoiceData] = useState([]);
  const [paidStatus, setPaidStatus] = useState(false);
  const [sentToCustomerStatus, setSentToCustomerStatus] = useState(false);
  const [invoiceView, setInvoiceView] = useState('InvoiceInformation') 
  const [memberData, setMemberData] = useState('') 
  const [btnColor1, setBtnColor1] = useState('#153243');
  const [btnColor2, setBtnColor2] = useState('#95B8D1');
  const [btnColor3, setBtnColor3] = useState('#95B8D1');
  const [btnColor4, setBtnColor4] = useState('#95B8D1');
  const handleInvoiceInfoView = () =>{
    setInvoiceView('InvoiceInformation');
    setBtnColor1('#153243');
    setBtnColor2('#95B8D1');
    setBtnColor3('#95B8D1');
    setBtnColor4('#95B8D1');
  }
  const [percentageAmount, setPercentageAmount] = useState(0);
  const [interest, setInterest] = useState(0);
  const [totalAmountWithInterest, setTotalAmountWithInterest] = useState(0);
  useEffect(()=>{
    document.title = 'Admin - Invoice'
    const getInvoicesData = async () =>{
      try {
        const response = await fetch(`https://globalworker-backend.vercel.app/api/invoice/admin/getsentinvoice/${invoiceId}`,{
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem('token'),
          },
        })
        if(!response.ok){
          console.log("Error while fetching the invoices data");
        }
        const data = await response.json();
        setInvoiceData(data)
        console.log(data)
        setPaidStatus(data.paid);
        setSentToCustomerStatus(data.sentToCustomerStatus);

        const memberDetailsRes = await fetch(`https://globalworker-backend.vercel.app/api/admin/members/memberdata/${data.invoice.user}`,{
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem('token'),
          },
        })
        if(!memberDetailsRes.ok){
          console.log("Error while fetching the members data");
        }
        const newBankData = await memberDetailsRes.json();
        console.log(newBankData)
        setMemberData(newBankData);
        if (newBankData.bankInfo) {
          if (newBankData.bankInfo.bankAndWiseOption === 'bankAccountInfo') {
            setInterest(8);
            let amount = data.invoice.totalAmount - (data.invoice.totalAmount * 8 / 100);
            setTotalAmountWithInterest(amount);
            const rem = data.invoice.totalAmount * (8 / 100);
            setPercentageAmount(rem);
          } else if (newBankData.bankInfo.bankAndWiseOption === 'wiseAccountInfo') {
            setInterest(6);
            let amount = data.invoice.totalAmount - (data.invoice.totalAmount * 6 / 100);
            setTotalAmountWithInterest(amount);
            const rem = data.invoice.totalAmount * (6 / 100);
            setPercentageAmount(rem);
          } else if (newBankData.bankInfo.bankAndWiseOption === 'paypalInfo') {
            if(data.invoice.totalAmount > 800){
              setInterest(10);
              let amount = data.invoice.totalAmount - (data.invoice.totalAmount * 10 / 100);
              setTotalAmountWithInterest(amount);
              const rem = data.invoice.totalAmount * (10 / 100);
              setPercentageAmount(rem);
            }
            else{
              setInterest(12);
              let amount = data.invoice.totalAmount - (data.invoice.totalAmount * 12 / 100);
              setTotalAmountWithInterest(amount);
              const rem = data.invoice.totalAmount * (12 / 100);
              setPercentageAmount(rem);
            }
          }
        }

      } catch (error) {
        console.log("Error while fetching the invoices data");
      }
    }
    getInvoicesData()
  }, [])

  useEffect(()=>{
    console.log(invoiceData)
  }, [invoiceData])

  const handleClientInfoView = () =>{
    setInvoiceView('ClientInformation');
    setBtnColor1('#95B8D1');
    setBtnColor2('#153243');
    setBtnColor3('#95B8D1');
    setBtnColor4('#95B8D1');
  }

  const handlePvtCustomerInfoView = () =>{
    setInvoiceView('PrivateCustomerInfo');
    setBtnColor1('#95B8D1');
    setBtnColor2('#95B8D1');
    setBtnColor3('#153243');
    setBtnColor4('#95B8D1');
  }

  const handlePaymentInfoView = () =>{
    setInvoiceView('PaymentInfo')
    setBtnColor1('#95B8D1');
    setBtnColor2('#95B8D1');
    setBtnColor3('#95B8D1');
    setBtnColor4('#153243');
    
  }
  

  return (
    <Layout setMWidth={"90%"} btnStatus={'none'}>
      
        <div className="invoice-btn-new-div">
          <button style={{backgroundColor: btnColor1}} className='valuta-heading invoice-View-Buttons' onClick={() => handleInvoiceInfoView()}>Fakturainformation</button>
          <button style={{backgroundColor: btnColor2}} className='valuta-heading invoice-View-Buttons' onClick={() => handleClientInfoView()}>Uppdragsgivare/Företag</button>
          <button style={{backgroundColor: btnColor3}} className='valuta-heading invoice-View-Buttons' onClick={() => handlePvtCustomerInfoView()}>Uppdragstagare/Medlem</button>
          <button style={{backgroundColor: btnColor4}} className='valuta-heading invoice-View-Buttons' onClick={() => handlePaymentInfoView()}>Utbetalningsinfo</button>
        </div>
        <hr style={{width: '90%', marginTop: '0px'}}/>

          {invoiceView === 'InvoiceInformation' && <InvoiceInfo setSentToCustomerStatus={setSentToCustomerStatus} sentToCustomerStatus={sentToCustomerStatus} paidStatus={paidStatus} setPaidStatus={setPaidStatus} invoiceData={invoiceData} interest={interest} totalAmountWithInterest={totalAmountWithInterest} percentageAmount={percentageAmount}/>}
          {invoiceView === 'ClientInformation' && <ClientInfo invoiceData={invoiceData}/>}
          {invoiceView === 'PrivateCustomerInfo' && <PrivateCustomer invoiceData={invoiceData}/>}
          {invoiceView === 'PaymentInfo' && <PaymentInfo invoiceData={memberData}/>}
          
    </Layout>
  )
}

export default InvoiceView;
