import React, {useEffect, useState} from "react";
import "../styles/GeneratedInvoice.css";
import { useParams } from "react-router-dom";

const SalaryInvoice = () => {
    const { invoiceId } = useParams();
    const [invoiceData, setInvoiceData] = useState([]);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [globalworkerBankInfo, setGlobalworkerBankInfo] = useState({});
    const [calculatedAmount, setCalculatedAmount] = useState(0);
    const [percentageAmount, setPercentageAmount] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [user, setUser] = useState([]);
    useEffect(() => {
      const getInvoiceData = async () => {
        document.title = 'Admin - Salary Invoice'
        try {
          const response = await fetch(`https://globalworker-backend.vercel.app/api/invoice/admin/getsentinvoice/${invoiceId}`,{
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem('token'),
          },
        })
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          console.log(data)
          setInvoiceData(data);

          const bankResponse = await fetch(`https://globalworker-backend.vercel.app/api/admin/bankinfo/getadminbankinfo`,{
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem('token'),
          },
        })

        if(!bankResponse.ok){
          throw new Error("Error while fetching the bank data");
        }

        const adminBankData = await bankResponse.json();
        setGlobalworkerBankInfo(adminBankData)
        console.log(adminBankData)

          const memberDetailsRes = await fetch(`https://globalworker-backend.vercel.app/api/admin/members/memberdata/${data.invoice.user}`,{
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem('token'),
          },
        })
        if(!memberDetailsRes.ok){
          console.log("Error while fetching the members data");
        }
        const newBankData = await memberDetailsRes.json();
        console.log(newBankData);

          if(newBankData?.bankInfo?.bankAndWiseOption === 'bankAccountInfo' && newBankData?.bankInfo?.bankAccountInfo){
            setPercentage(8);
            const result = data.invoice.totalAmount - (data.invoice.totalAmount * 8) / 100;
  
            const formattedAmount = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: data.clientData?.privateClient?.pvtCurrencyCode || data.clientData?.organizationClient?.orgCurrencyCode,
            }).format(result);
  
            setCalculatedAmount(formattedAmount)
  
            const rem = data.invoice.totalAmount * (8 / 100);
            setPercentageAmount(rem);
          }

          if(newBankData?.bankInfo?.bankAndWiseOption === 'wiseAccountInfo' && newBankData?.bankInfo?.wiseAccountInfo){
            setPercentage(6);
            const result = data.invoice.totalAmount - (data.invoice.totalAmount * 6) / 100;
  
            const formattedAmount = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: data.clientData?.privateClient?.pvtCurrencyCode || data.clientData?.organizationClient?.orgCurrencyCode,
            }).format(result);
  
            setCalculatedAmount(formattedAmount)
  
            const rem = data.invoice.totalAmount * (6 / 100);
            setPercentageAmount(rem);
          }

          if(newBankData?.bankInfo?.bankAndWiseOption === 'paypalInfo' && newBankData?.bankInfo?.paypalInfo){
            if(data.invoice.totalAmount > 800){
              setPercentage(10);
              const result = data.invoice.totalAmount - (data.invoice.totalAmount * 10) / 100;
    
              const formattedAmount = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: data.clientData?.privateClient?.pvtCurrencyCode || data.clientData?.organizationClient?.orgCurrencyCode,
              }).format(result);
    
              setCalculatedAmount(formattedAmount)
    
              const rem = data.invoice.totalAmount * (10 / 100);
              setPercentageAmount(rem);
            }
            else{
              setPercentage(12);
              const result = data.invoice.totalAmount - (data.invoice.totalAmount * 12) / 100;
    
              const formattedAmount = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: data.clientData?.privateClient?.pvtCurrencyCode || data.clientData?.organizationClient?.orgCurrencyCode,
              }).format(result);
    
              setCalculatedAmount(formattedAmount)
    
              const rem = data.invoice.totalAmount * (12 / 100);
              setPercentageAmount(rem);
            }
          }
          

          const userId = data.invoice.user;
          console.log(userId)

          const userResponse = await fetch(`https://globalworker-backend.vercel.app/api/auth/admin/getfreelanceruser/${userId}`,{
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem('token'),
          },
        })
        if(!response.ok){
          throw new Error("Error while fetching the user data");
        }
        const userData = await userResponse.json();
        console.log(userData)
        setUser(userData)

        } catch (error) {
          console.error("Error while getting Client info:", error);
        }
      };
      getInvoiceData();
    }, []);
  
    return (
      <div className="generatedInvoiceContainer">
        <div className="mt-5 mb-2" style={{width: '80%', display: 'flex', justifyContent: 'end'}}>
        <table>
          <tbody>
          <tr>
            <th>Invoice No.</th>
            <td>{invoiceData?.invoice?.invoiceNumber}</td>
          </tr>
          </tbody>
        </table>
      </div>
        <div className="g-invoiceHeader mb-4">
          <img src={require("../assets/hela@10x-100.png")} alt="noimgae" />
          <table className="dateandcust-table">
          <tbody>
            <tr>
              <th className="g-invoiceHeader-col">Date</th>
              <th className="g-invoiceHeader-col">Customer</th>
            </tr>
            <tr>
              <td className="g-invoiceHeader-col">{currentDate.toLocaleDateString()}</td>
              <td className="g-invoiceHeader-col">{invoiceData?.clientData?.customerNumber}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className="mb-1 salaryNumber-container">
            <table>
                <tbody>
                    <tr>
                        <th>Salary:</th>
                        <td>{invoiceData?.invoice?.invoiceNumber}</td>
                    </tr>
                </tbody>
            </table>

        </div>
        <div className="invoiceCustomerDetails mb-4 mt-2">
          <table className="invoiceCustomerDetailsTable" >
            <tbody>
            <tr>
              <th>Our Reference:</th>
              <td>{invoiceData?.invoice?.clientName}</td>
            </tr>
            <tr>
              <td>{invoiceData?.userData?.profileOption === 'private' ? invoiceData?.userData?.privateUserInfo?.pvtFname : invoiceData?.userData?.organizationUserInfo?.orgFname} {invoiceData?.userData?.profileOption === 'private' ? invoiceData?.userData?.privateUserInfo?.pvtLname : invoiceData?.userData?.organizationUserInfo?.orgLname}</td>
              <td>{invoiceData?.clientData?.clientOption === "private"
                  ? invoiceData?.clientData?.privateClient?.pvtStreet
                  : invoiceData?.clientData?.organizationClient?.orgStreet}
              </td>
            </tr>
            <tr>
              <td>{user.email}</td>
              <td>{`${invoiceData?.clientData?.clientOption === "private"
                    ? invoiceData?.clientData?.privateClient?.pvtPostalCode
                    : invoiceData?.clientData?.organizationClient?.orgPostalCode} 
                    ${invoiceData?.clientData?.clientOption === "private"
                    ? invoiceData?.clientData?.privateClient?.pvtCity
                    : invoiceData?.clientData?.organizationClient?.orgCity}`}</td>
            </tr>
            <tr>
              <th>Your Reference:</th>
              <td>{invoiceData?.clientData?.clientOption === "private"
                    ? invoiceData?.clientData?.privateClient?.pvtCountry
                    : invoiceData?.clientData?.organizationClient?.orgCountry}
              </td>
            </tr>
            <tr>
              <td>{invoiceData?.clientData?.clientOption === "private"
                    ? invoiceData?.clientData?.privateClient?.pvtReferenceName
                    : invoiceData?.clientData?.organizationClient?.orgReferenceName}
              </td>
              <td></td>
            </tr>
            <tr>
              <th>Customer Email:</th>
              <td>{invoiceData?.clientData?.clientOption === "private"
                    ? invoiceData?.clientData?.privateClient?.pvtRecipientEmail
                    : invoiceData?.clientData?.organizationClient?.orgRecipientEmail}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className="invoiceAmountContainer mb-4">
          <table className="invoiceAmountTable">
            <tbody>
            <tr>
              <th>Work Description</th>
              <th>Quantity</th>
              <th>Unit</th>
              <th>Price</th>
              <th>Amount</th>
            </tr>
              {invoiceData?.invoiceAmount?.map((data, index) => (
                <tr key={index}>
                  <td >{data.workDescription}</td>
                  <td >{data.quantity}</td>
                  <td >{data.unit}</td>
                  <td >{data.fixedPrice == null ? data.price : data.fixedPrice}</td>
                  <td >{data.totalAmount}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="totalAmount-container">
            <table>
                <tbody>
                    <tr>
                        <th>Total invoice amount</th>
                        <td>{invoiceData?.invoice?.totalAmount} {invoiceData?.clientData?.privateClient?.pvtCurrencyCode ||invoiceData?.clientData?.organizationClient?.orgCurrencyCode}</td>
                    </tr>
                    <tr>
                        <th>Our service fee {percentage}%</th>
                        <td>{percentageAmount.toFixed(2)} {invoiceData?.clientData?.privateClient?.pvtCurrencyCode ||invoiceData?.clientData?.organizationClient?.orgCurrencyCode}</td>
                    </tr>
                    <tr>
                        <th>Transaction fee</th>
                        <td>0 {invoiceData?.clientData?.privateClient?.pvtCurrencyCode ||invoiceData?.clientData?.organizationClient?.orgCurrencyCode}</td>
                    </tr>
                    <tr>
                        <th>Tax payment fee</th>
                        <td>0 {invoiceData?.clientData?.privateClient?.pvtCurrencyCode ||invoiceData?.clientData?.organizationClient?.orgCurrencyCode}</td>
                    </tr>
                    <tr>
                        <th>Total tax to pay</th>
                        <td>0 {invoiceData?.clientData?.privateClient?.pvtCurrencyCode ||invoiceData?.clientData?.organizationClient?.orgCurrencyCode}</td>
                    </tr>
                    <tr>
                        <th>Net salary to pay</th>
                        <td>{calculatedAmount}</td>
                    </tr>
                </tbody>
            </table>
          </div>
        </div>
        <div className="invoice-note-container mb-4">
          <p>
            Note: Please add the invoice number: {invoiceData?.invoice?.invoiceNumber} in the reference section
            of your online payment
          </p>
          <p>We only accept EUR</p>
        </div>
        <div className="row invoice-bankInfo-container mb-4">
          <div className="col">
            <h5>URL</h5>
            <p className="mb-4">Globalworker.se</p>
            <h5>Mail</h5>
            <p>info@globalworker.se</p>
          </div>
          <div className="col">
            <table className="invoice-bankInfo-table">
            <tbody>
              <tr>
                <th>BIC/SWIFT CODE</th>
                <td>{globalworkerBankInfo?.ibanInformation?.swiftCode}</td>
              </tr>
              <tr>
                <th>IBAN</th>
                <td>{globalworkerBankInfo?.ibanInformation?.iban}</td>
              </tr>
              <tr>
                <th>Bank Name</th>
                <td>{globalworkerBankInfo?.ibanInformation?.ibanBankName}</td>
              </tr>
              <tr>
                <th>City</th>
                <td>{globalworkerBankInfo?.ibanInformation?.city}</td>
              </tr>
              <tr>
                <th>Country</th>
                <td>{globalworkerBankInfo?.ibanInformation?.country}</td>
              </tr>
            </tbody>
            </table>
          </div>
        </div>
        <footer className="mt-5" style={{ width: "80%" }}>
          <p>GLOBAL FREELANCER WORKERS LTD</p>
          <p>STRON LEGAL CLUBHOUSE ST JAMES 8</p>
          <p>SW1Y 4JU, United Kingdom, Company No. 14534594</p>
        </footer>
      </div>
    );
}

export default SalaryInvoice
