import React, {useEffect, useState} from 'react'

function PrivateCustomer({invoiceData}) {
  const [privateUser, setPrivateUser] = useState({
    firstName: invoiceData?.userData?.privateUserInfo?.pvtFname || "",
    lastName: invoiceData?.userData?.privateUserInfo?.pvtLname || "",
    email: invoiceData?.userData?.privateUserInfo?.pvtUserEmail || "",
    mobileNo: invoiceData?.userData?.privateUserInfo?.pvtMobNo || "",
    telephone: invoiceData?.userData?.privateUserInfo?.pvtTel || "",
    skypeName: invoiceData?.userData?.privateUserInfo?.pvtSkypeName || "",
  });

  useEffect(()=>{
    console.log(invoiceData?.userData?.privateUserInfo?.pvtFname)
  }, [invoiceData])
  return (
    <>
    <div className='client-info-main-container'>
    <div className='client-info-radio-container'>
    <div className='Privatpersons-container'>
      <h6 className='Privatpersons-heading'>Privatpersons eller</h6>
      <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                disabled
                defaultChecked
              />
              <label className="form-check-label" htmlFor="flexRadioDefault1">
                Privatperson
              </label>
            </div>
        </div>
        </div>
      <form className="form-div row g-3">
            <div className="">
              <label htmlFor="pvtFName" className="label form-label">
                    Förnamn
              </label>
              <input type="text" className="form-input form-control" id="pvtFName" disabled value={privateUser.firstName} />
            </div>

            <div className="">
              <label htmlFor="pvtLName" className="label form-label">
                    Efternamn
              </label>
              <input value={privateUser.lastName} type="text" className="form-input form-control" id="pvtLName" disabled />
            </div>
            <div className="">
              <label htmlFor="pvtEmail" className="label form-label">
                    E-post
              </label>
              <input value={privateUser.email} type="email" className="form-input form-control" id="pvtEmail" disabled />
            </div>
            <div className="">
              <label htmlFor="pvtTel" className="label form-label">
              Telefon nummer
              </label>
              <input value={privateUser.telephone} type="text" className="form-input form-control" id="pvtTel" disabled />
            </div>
            <div className="">
              <label htmlFor="pvtMobNo" className="label form-label">
              Mobil nummer
              </label>
              <input value={privateUser.mobileNo} type="text" className="form-input form-control" id="pvtMobNo" disabled />
            </div>
            <div className="">
              <label htmlFor="pvtSkypeName" className="label form-label">
              Skype namn
              </label>
              <input value={privateUser.skypeName} type="text" className="form-input form-control" id="pvtSkypeName" disabled />
            </div>
        </form>
        </div>
    </>
  )
}

export default PrivateCustomer
