import React from 'react';
import adminContext from "./AdminContext";
const AdminProvider = ({children}) => {

    const saveAdminProfile = async (newdata) =>{
        try {
            const response = await fetch("https://globalworker-backend.vercel.app/api/admin/saveadminprofile",{
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "auth-token": localStorage.getItem('token'),
                },
                body: JSON.stringify(newdata),
            })
            if(!response.ok){
                console.log(`Error while saving profile ${response}`);
            }
            const data = await response.json()
            console.log(data)
            return true;
        } catch (error) {
            console.log("Error while saving profile");
        }
    }

    //Admin Save Bank data
    const saveAdminBankInfo = async (bankInfo) =>{
        try {
            const response = await fetch("https://globalworker-backend.vercel.app/api/admin/bankinfo/saveadminbankinfo",{
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "auth-token": localStorage.getItem('token'),
                },
                body: JSON.stringify(bankInfo),
            })
            if(!response.ok){
                throw new Error(`Error while saving Bank Data ${response}`);
            }
            const data = await response.json()
            console.log(data)
            return true;
        } catch (error) {
            console.log("Error while saving Bank Data");
        }
    }


    const sendInvoiceToCustomer = async (invoiceData) => {
        try {
          //generate invoice
          const response = await fetch(`https://globalworker-backend.vercel.app/api/admin/invoicepdf/generateinvoicepdfnew`, {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
          },
            body: JSON.stringify(invoiceData),
          });
      
          if (!response.ok) {
            throw new Error('Error while generating PDF.');
          }
      
          const sendEmailResponse = await fetch(`https://globalworker-backend.vercel.app/api/admin/invoicepdf/sendinvoicepdf`, {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
            body: JSON.stringify(invoiceData),
          });
          console.log(invoiceData)
      
          if (!sendEmailResponse.ok) {
            throw new Error('Error while sending email with PDF attachment.');
          }
      
          console.log('Email sent to the client');
          return true;
        } catch (error) {
          console.error('Error while sending pdf to customer:', error);
          return false;
        }
      };


    const sendInterestInvoiceToCustomer = async (invoiceData) => {
        try {
          console.log(invoiceData)
          const response = await fetch(`https://globalworker-backend.vercel.app/api/admin/invoicepdf/generateinterestinvoicepdf`, {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
          },
            body: JSON.stringify(invoiceData),
          });
      
          if (!response.ok) {
            throw new Error('Error while generating PDF.');
          }
      
          const sendEmailResponse = await fetch(`https://globalworker-backend.vercel.app/api/admin/invoicepdf/sendinterestinvoicepdf`, {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
              body: JSON.stringify({clientData: invoiceData.invoiceData.clientData, invoiceNumber: invoiceData.invoiceData.invoice.invoiceNumber}),
          });
      
          if (!sendEmailResponse.ok) {
            throw new Error('Error while sending email with PDF attachment.');
          }
      
          console.log('Email sent to the client');
          return true;
        } catch (error) {
          console.error('Error while sending interest invoice:', error);
          return false;
        }
      };

      const sendSalaryMail = async (data) =>{
        try {
          console.log(data);
          const sendEmailResponse = await fetch(`https://globalworker-backend.vercel.app/api/admin/invoicepdf/salarymail`, {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
          },
            body: JSON.stringify(data),
          });
      
          if (!sendEmailResponse.ok) {
            throw new Error('Error while sending email');
          }

          console.log('Email sent to the freelancer');
          return true;
        } catch (error) {
          console.error('Error while sending Mail:', error);
          return false;
        }
      }


      const sendCorrectionInvoiceMail = async (data) =>{
        try {
          const sendEmailResponse = await fetch(`https://globalworker-backend.vercel.app/api/admin/invoicepdf/incorrectinvoicemail`, {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
              body: JSON.stringify(data),
          });
      
          if (!sendEmailResponse.ok) {
            throw new Error('Error while sending email');
          }

          console.log('Email sent to the freelancer');
          return true;
        } catch (error) {
          console.error('Error while sending Mail:', error);
          return false;
        }
      }

      //send reminder to client
    const sendReminderToClient = async (data, subject) => {
        try {
          console.log(subject)
          const response = await fetch(`https://globalworker-backend.vercel.app/api/admin/invoicepdf/generateinvoicepdfnew`, {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
          },
            body: JSON.stringify(data),
          });
      
          if (!response.ok) {
            throw new Error('Error while generating PDF.');
          }
      
          const sendEmailResponse = await fetch(`https://globalworker-backend.vercel.app/api/admin/invoicepdf/sendReminder`, {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
          },
            body: JSON.stringify({clientData: data.invoiceData.clientData, subject}),
          });
      
          if (!sendEmailResponse.ok) {
            throw new Error('Error while sending Reminder with PDF attachment.');
          }
      
          console.log('Reminder sent to the client');
          return true;
        } catch (error) {
          console.error('Error while sending Alert:', error);
          return false;
        }
      };
    
  return (
    <adminContext.Provider value={{sendCorrectionInvoiceMail, saveAdminProfile, saveAdminBankInfo, sendInvoiceToCustomer, sendReminderToClient, sendInterestInvoiceToCustomer, sendSalaryMail}}>
        {children}
    </adminContext.Provider>
  )
}

export default AdminProvider
