import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import "../styles/Members.css";
import { Link } from "react-router-dom";
function Members() {
    const [userData, setUserData] = useState([]);
    const [emailInput, setEmailInput] = useState("");
    const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const getAllUsers = async () => {
        try {
            const response = await fetch(
              "https://globalworker-backend.vercel.app/api/auth/getallusers",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "auth-token": localStorage.getItem('token'),
                },
              }
            );
            if(!response.ok){
              console.log("Error while fetching data");
            }
            const data = await response.json();
            console.log(data);
            setUserData(data)
        } catch (error) {
            console.log("Error while fetching data");
        }
    };
    getAllUsers();
  }, []);

  const searchEmailOnChange = (e) =>{
    setEmailInput(e.target.value);
  }

  const handleSearch = () =>{
    let filtered = userData;
    if(emailInput !== ""){
      const inputLowerCase = emailInput.toLowerCase();
      filtered = filtered.filter(data =>
        data.email.toLowerCase() === inputLowerCase ||
        data.name.toLowerCase() === inputLowerCase ||
        data.name.toLowerCase().includes(inputLowerCase) ||
        data.name.toLowerCase().split(' ').some(namePart => namePart.startsWith(inputLowerCase))
      )
    }
    if(emailInput === ""){
      setFilteredData(userData)
    }
    setFilteredData(filtered)
  }
  return (
    <Layout setMWidth={"90%"} btnStatus={"none"}>
      <form className="row row-col client-data-col-divs-lg-auto g-3 align-items-center members-form mt-2 mb-3">
        <div className="col client-data-col-div-12 mem-email-container">
          <label htmlFor="inputEmail4" className="form-label mem-label">
            Sök efter namn eller e-post
          </label>
          <input type="text" className="form-control" id="inputEmail4" value={emailInput} onChange={searchEmailOnChange} />
        </div>
        <div className="col client-data-col-div-12 member-form-btn-div">
          <button type='button' onClick={handleSearch} className="btn btn-primary mem-form-btn">Sök</button>
        </div>
      </form>
      <hr style={{ width: "80%" }} />

      <div className="row mb-3 row-1 table-rows table-rows-heading ">
        { filteredData.length === 0 ? <>
          <div className="row client-list-row">
        <div className="col client-data-col-div">
          <h6>Namn</h6>
          {userData.map((data, index) =>(
            <div  key={index} className="client-data-col">
                <p>{data.name}</p>
            </div>
          ))}
        </div>
        <div className="col client-data-col-div">
          <h6>E-post</h6>
          {userData.map((data, index) =>(
            <div key={index} className="client-data-col">
                <p >{data.email}</p>
            </div>
          ))}
        </div>
        <div className="col client-data-col-div">
          <h6>Datum</h6>
          {userData.map((data, index) =>(
            <div key={index} className="client-data-col">
                <p >{data.date}</p>
            </div>
          ))}
        </div>
        <div className="col client-data-col-div">
          <h6>Details</h6>
          {userData.map((data, index) =>(
            <div key={index} className="client-data-col">
                <div style={{marginBottom: '1rem'}}>
                  <Link to={`/members/member-info/${data._id}`}>Detaljer</Link>|
                  <Link to={`/admin/member/mailbox/${data._id}`}>Skicka e-post</Link>|
                  <Link to={`/admin/member/memberInvoices/${data._id}`}>Faktura</Link>
                </div>
            </div>
          ))}
        </div>
        </div>
        </> : <>
        <div className="row client-list-row">
        <div className="col client-data-col-div">
          <h6>Namn</h6>
          {filteredData.map((data, index) =>(
            <div  key={index} className="client-data-col">
                <p>{data.name}</p>
            </div>
          ))}
        </div>
        <div className="col client-data-col-div">
          <h6>E-post</h6>
          {filteredData.map((data, index) =>(
            <div key={index} className="client-data-col">
                <p >{data.email}</p>
            </div>
          ))}
        </div>
        <div className="col client-data-col-div">
          <h6>Datum</h6>
          {filteredData.map((data, index) =>(
            <div key={index} className="client-data-col">
                <p >{data.date}</p>
            </div>
          ))}
        </div>
        <div className="col client-data-col-div">
          <h6>Details</h6>
          {filteredData.map((data, index) =>(
            <div key={index} className="client-data-col">
                <Link to={`/members/member-info/${data._id}`}>Detaljer</Link>|
                  <Link to={`/admin/member/mailbox/${data._id}`}>Skicka e-post</Link>|
                  <Link to={`/admin/member/memberInvoices/${data._id}`}>Faktura</Link>  
            </div>
          ))}
        </div>
        </div>
        </>}
      </div>
    </Layout>
  );
}

export default Members;
