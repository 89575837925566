import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import '../styles/Navbar.css'
const AdminNavbar = () => {
  const navigate = useNavigate();
    const handleLogout = () =>{
      document.body.style.overflow = '';
      localStorage.removeItem('token');
      navigate('/signin')
      console.log("Logout");
    }
  return (
    <nav className="navbar bg-body-tertiary fixed-top">
      <div className="container-fluid">
        {/* Hamburger button on the left */}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasWithBothOptions"
          aria-controls="offcanvasWithBothOptions"
          aria-label="Toggle navigation"
          data-bs-theme="dark"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Dropdown for languages */}

        <div className="dropdown">
          <button
            className="btn btn-secondary dropdown-toggle"
            type="button"
            id="languageDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Svenska (Sverige)
          </button>
          <ul className="dropdown-menu" aria-labelledby="languageDropdown">
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => changeLanguage("Svenska (Sverige)")}
              >
                Svenska (Sverige)
              </a>
            </li>
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => changeLanguage("English")}
              >
                English
              </a>
            </li>
            {/* Add more language options as needed */}
          </ul>
        </div>

        {/* User profile name */}
        <div className="user-prof-div">
          <img
            src={require("../assets/Profile icon.png")}
            alt="user profile img"
          />
          <h6 className="userprof-heading">
            <Link className="linkId" to={"#"}>
              Name
            </Link>
          </h6>
        </div>

        {/* Dashboard */}
        <div className="dashboard-div">
          <Link
            style={{ alignItems: "center" }}
            className="linkId"
            to={"/"}
          >
            <img
              src={require("../assets/Dashboard icon.png")}
              alt="dashboard img"
            />
            <h6 className="dashboard-heading">Kontrollpanelen</h6>
          </Link>
        </div>

        {/* Logo in the right corner */}
        <a className="navbar-brand globalworker-img" href="#">
          <img src={require("../assets/hela vit@10x.png")} alt="Logo" />
        </a>

        {/* Offcanvas menu */}
        <div
          className="sidebar-menu offcanvas offcanvas-start"
          tabIndex="-1"
          id="offcanvasWithBothOptions"
          data-bs-scroll="true"
          aria-labelledby="offcanvasWithBothOptionsLabel"
        >
          <div className="offcanvas-header offcanvas-close-div">
              <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>

          <Link className="dashboard-div-side-menu mb-4" style={{textDecoration: 'none', color: 'white'}} to={'/'} >
            <img
              src={require("../assets/Dashboard icon.png")}
              alt="dashboard img"
            />
            <h2 style={{paddingLeft: '10px', marginBottom: '2px'}} className="dashboard-heading-side-menu">Kontrollpanelen</h2>
          </Link>
          {/* User profile name */}
          <div className="user-prof-div-side-menu mb-4">
            <img
              src={require("../assets/Profile icon.png")}
              alt="user profile img"
            />
            <h6 className="userprof-heading-side-menu">Name</h6>
          </div>
          <div className="dropdown mb-4">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              id="languageDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Svenska (Sverige)
            </button>
            <ul className="dropdown-menu" aria-labelledby="languageDropdown">
              <li>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => changeLanguage("Svenska (Sverige)")}
                >
                  Svenska (Sverige)
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => changeLanguage("English")}
                >
                  English
                </a>
              </li>
              {/* Add more language options as needed */}
            </ul>
          </div>
          <div >
            <button onClick={handleLogout} style={{padding: '8px 15px 8px 15px', borderRadius: '10px'}}>Logout</button>
          </div>

          {/* Dashboard */}
        </div>
      </div>
    </nav>
  )
}
export default AdminNavbar

function changeLanguage(language) {
    document.getElementById("languageDropdown").innerText = language;
  }