import React, {useState, useEffect, useContext} from 'react';
import '../styles/Currency.css';
import '../styles/InvoiceInfo.css';
import { useNavigate } from 'react-router-dom';
import adminContext from '../context/AdminContext';
import Spinner from './Spinner';
function InvoiceInfo({ setSentToCustomerStatus, paidStatus, setPaidStatus, invoiceData, totalAmountWithInterest, interest, percentageAmount}) {
  const navigate = useNavigate();
  const context = useContext(adminContext);
  const { sendCorrectionInvoiceMail, sendSalaryMail, sendInterestInvoiceToCustomer, sendInvoiceToCustomer, sendReminderToClient } = context;
  const [loading, setLoading] = useState(false);
  const sendInvoice = async () =>{
    try {
      setLoading(true);
      setSentToCustomerStatus(true)
      const success = await sendInvoiceToCustomer({invoiceData: invoiceData, totalAmountWithInterest: totalAmountWithInterest, interest: interest, percentageAmount: percentageAmount, sentToCustomerStatus: true})
      return success;
    } catch (error) {
      console.log("error occurred");
      appendAlert("error occurred. please try again later.", "danger");
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Smooth scrolling
      });
      return false;
    } finally {
      setLoading(false);
      appendAlert("Invoice sent successfully!!.", "success");
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Smooth scrolling
      });
    }
  }

  const sendInterestInvoice = async () =>{
    const conformationMessage = window.confirm('Are you sure you want to send interest invoice to customer?');
    if(conformationMessage){
    try {
        setLoading(true);
        const success = await sendInterestInvoiceToCustomer({invoiceData: invoiceData, totalAmountWithInterest: totalAmountWithInterest, interest: interest, percentageAmount: percentageAmount})
        return success;
      } catch (error) {
      console.log("error occurred");
      appendAlert("error occurred. please try again later.", "danger");
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Smooth scrolling
      });
        return false;
    } finally {
      setLoading(false);
      appendAlert("Invoice sent successfully!!.", "success");
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Smooth scrolling
      });
    }
    }
  }

  const sendReminder = async (reminderNumber) =>{
    try {
      const subject = `Reminder ${reminderNumber} - Invoice No: ${invoiceData?.invoice?.invoiceNumber}`
      setLoading(true);
      const success = await sendReminderToClient({invoiceData: invoiceData, totalAmountWithInterest: totalAmountWithInterest, interest: interest, percentageAmount: percentageAmount}, {subject: subject})
      return success;
    } catch (error) {
      console.log("error occurred");
      appendAlert("error occurred. please try again later.", "danger");
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Smooth scrolling
      });
        return false;
    } finally {
      setLoading(false);
      appendAlert("Reminder 1 sent successfully!!.", "success");
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Smooth scrolling
      });
    }
  }

  const sendSalary = async () =>{
    const conformationMessage = window.confirm('Are you sure you want to send salary to Freelancer?');
    if(conformationMessage){
    try {
      setLoading(true);
        setPaidStatus(true);
        const success = await sendSalaryMail({invoiceData: invoiceData.invoice, userData: invoiceData.userData, paid: true});
        return success;
      } catch (error) {
      console.log("error occurred");
      appendAlert("error occurred. please try again later.", "danger");
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Smooth scrolling
      });
      return false;
    } finally {
      setLoading(false);
      appendAlert("salary sent successfully!!.", "success");
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Smooth scrolling
      });
    }
    }
  }

  const sendMailForCorrection = async () =>{
    try {
      setLoading(true);
      const success = await sendCorrectionInvoiceMail({userData: invoiceData.userData, invoiceNumber: invoiceData.invoice.invoiceNumber});
      return success;
    } catch (error) {
      console.log("error occurred");
      appendAlert("error occurred. please try again later.", "danger");
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Smooth scrolling
      });
      return false;
    } finally {
      setLoading(false);
      appendAlert("correction email sent successfully!!.", "success")
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Smooth scrolling
      });
    }
  }

  const alertPlaceholder = document.getElementById('liveAlertPlaceholder')
  const appendAlert = (message, type) => {
    const wrapper = document.createElement('div')
    wrapper.innerHTML = [
      `<div class="alert alert-${type} alert-dismissible" role="alert">`,
      `   <div>${message}</div>`,
      '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
      '</div>'
    ].join('')
  
    alertPlaceholder.append(wrapper)
  }

  return (
    <>
    <div id="liveAlertPlaceholder" style={{width: '100%'}}></div>
    <div className="row mb-3 row-1 table-rows inv-info-headings">
          <div className="col">
            <h6>Benämning</h6>
            {invoiceData?.invoiceAmount?.map((data, index) =>(
              <div key={index}>
                <p>{data.workDescription}</p>
              </div>
            ))}
          </div>
          <div className="col">
            <h6>Antal</h6>
            {invoiceData?.invoiceAmount?.map((data, index) =>(
              <div key={index}>
                <p>{data.unit === 'fast-pris' ? "-" : data.quantity}</p>
              </div>
            ))}
          </div>
          <div className="col">
            <h6>Enhet</h6>
            {invoiceData?.invoiceAmount?.map((data, index) =>(
              <div key={index}>
                <p>{data.unit}</p>
              </div>
            ))}
          </div>
          <div className="col">
            <h6>A-pris</h6>
            {invoiceData?.invoiceAmount?.map((data, index) =>(
              <div key={index}>
                <p>{data.unit === 'fast-pris' ? data.fixedPrice : data.price}</p>
              </div>
            ))}
            </div>
          <div className="col">
            <h6>Belopp</h6>
            {invoiceData?.invoiceAmount?.map((data, index) =>(
              <div key={index}>
                <p>{data.totalAmount}</p>
              </div>
            ))}
          </div>
        </div>
        <hr className='custHr' style={{width: '90%', marginTop: '0px'}}/>
        <div className='invoice-main-container'>

          <div className='invoice-multi-heading-div'>
          {loading === true && <Spinner />}

            <div className="inv-info-heading-row">
                <div className="col"><button className='brightRed invoice-multi-headin btn btn-primary' style={{border: 'none'}} onClick={sendMailForCorrection}>Skicka tillbaka till medlem</button></div>
                <div className="col "><button className='blueHeading invoice-multi-headin btn btn-primary' style={{border: 'none'}} onClick={sendSalary}>{paidStatus ? "Medlemmen har fått lön" : "Lönen utbetald till Medlem"}</button></div>
                <div className="col "><button className='redHeading invoice-multi-headin btn btn-primary' style={{border: 'none'}} onClick={sendInvoice}>Skicka till kund</button></div>
            </div>

            <div className="inv-info-heading-row">
                <div className="col "><button className='greyHeading invoice-multi-headin btn btn-primary' style={{border: 'none'}}>Kopia</button></div>
                <div className="col " ><button className='blueHeading invoice-multi-headin btn btn-primary' style={{border: 'none'}} onClick={() => { navigate(`/admin/generated-salary-invoice/${invoiceData.invoice._id}`) }}>Lönespecifikation</button></div>
                <div className="col "><button className='blueHeading invoice-multi-headin btn btn-primary' style={{border: 'none'}} onClick={() => { navigate(`/admin/generated-invoice/${invoiceData.invoice._id}`) }} >Visa faktura</button></div>
                <div className="col "><button className='redHeading invoice-multi-headin btn btn-primary' style={{border: 'none'}} onClick={()=>{sendReminder(1)}}>Påminnelse 1</button></div>
            </div>

            <div className="inv-info-heading-row">
                <div className="col "><button className='redHeading invoice-multi-headin btn btn-primary' style={{border: 'none'}} onClick={()=>{sendReminder(2)}}>Påminelse 2</button></div>
                <div className="col " ><button className='yellowHeading invoice-multi-headin btn btn-primary' style={{border: 'none'}} onClick={sendInterestInvoice}>Skapa Räntefaktura</button></div>
                <div className="col "><button className='blueHeading invoice-multi-headin btn btn-primary' style={{border: 'none'}} onClick={() => { navigate(`/admin/credit-invoice/${invoiceData.invoice._id}`) }}>Skapa Kreditfaktura</button></div>
            </div>

            </div>
            <div className='invoice-list-div'>
              <div className='invoice-list-div-inner' >
                <div className='invoice-details'>
                    <p>Totalt fakturabelopp</p>
                    <p>{invoiceData?.invoice?.totalAmount} {invoiceData?.clientData?.clientOption === 'private' ? invoiceData?.clientData?.privateClient?.pvtCurrencyCode : invoiceData?.clientData?.organizationClient?.orgCurrencyCode}</p>
                </div>
                <div className='invoice-details'>
                    <p>Vår serviceavgift {interest}%</p>
                    <p>{percentageAmount.toFixed(2)} {invoiceData?.clientData?.clientOption === 'private' ? invoiceData?.clientData?.privateClient?.pvtCurrencyCode : invoiceData?.clientData?.organizationClient?.orgCurrencyCode}</p>
                </div>
                <div className='forTxtWrap invoice-details'>
                    <p>Transaktionsavgifter</p>
                    <p>0 {invoiceData?.clientData?.clientOption === 'private' ? invoiceData?.clientData?.privateClient?.pvtCurrencyCode : invoiceData?.clientData?.organizationClient?.orgCurrencyCode}</p>
                </div>
                <div className='forTxtWrap invoice-details'>
                    <p>Skattebetalningsavgift</p>
                    <p>0 {invoiceData?.clientData?.clientOption === 'private' ? invoiceData?.clientData?.privateClient?.pvtCurrencyCode : invoiceData?.clientData?.organizationClient?.orgCurrencyCode}</p>
                </div>
                <div className='invoice-details'>
                    <p>Total skatt att betala</p>
                    <p>0 {invoiceData?.clientData?.clientOption === 'private' ? invoiceData?.clientData?.privateClient?.pvtCurrencyCode : invoiceData?.clientData?.organizationClient?.orgCurrencyCode}</p>
                </div>
                <div className='txtRed mb-5 mt-4 invoice-details'>
                    <p>Netto lön att betala</p>
                    <p>{totalAmountWithInterest.toFixed(2)} {invoiceData?.clientData?.clientOption === 'private' ? invoiceData?.clientData?.privateClient?.pvtCurrencyCode : invoiceData?.clientData?.organizationClient?.orgCurrencyCode}</p>
                </div>
                </div>

            </div>
        </div>
    </>
  )
}

export default InvoiceInfo;
