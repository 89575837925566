import React, {useState, useEffect} from 'react'
import Layout from './Layout'
import '../styles/InvoiceView.css'
import { useParams } from 'react-router-dom';
import MemberInformation from './MemberInformation'
import MemberBankInfo from './MemberBankInfo'

const SpecificMemberDetails = () => {
    const {memberId} = useParams();
    const [memberData, setMemberData] = useState([]);
    const [invoiceView, setInvoiceView] = useState('PrivateCustomerInfo')
    const [btnColor3, setBtnColor3] = useState('#153243');
    const [btnColor4, setBtnColor4] = useState('#95B8D1');
    
    useEffect(()=>{
      document.title = 'Admin - Member Information'
      const getMemberData = async () =>{
        try {
          const response = await fetch(`https://globalworker-backend.vercel.app/api/admin/members/memberdata/${memberId}`,{
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
          })
          if(!response.ok){
            console.log("Error while fetching the member data");
          }
          const data = await response.json();
          console.log(data)
          setMemberData(data)
       
        } catch (error) {
          console.log("Error while fetching the member data");
        }
      }
      getMemberData()
    }, [])
  
    useEffect(()=>{
      console.log(memberData)
    }, [memberData])
  

  
    const handlePvtCustomerInfoView = () =>{
      setInvoiceView('PrivateCustomerInfo');
      setBtnColor3('#153243');
      setBtnColor4('#95B8D1');
    }
  
    const handlePaymentInfoView = () =>{
      setInvoiceView('PaymentInfo')
      setBtnColor3('#95B8D1');
      setBtnColor4('#153243');
      
    }

    return (
      <Layout setMWidth={"90%"} btnStatus={'none'}>
          <div className="invoice-btn-new-div">
            <button style={{backgroundColor: btnColor3}} className='valuta-heading invoice-View-Buttons' onClick={() => handlePvtCustomerInfoView()}>Uppdragstagare/Medlem</button>
            <button style={{backgroundColor: btnColor4}} className='valuta-heading invoice-View-Buttons' onClick={() => handlePaymentInfoView()}>Utbetalningsinfo</button>
          </div>
          <hr style={{width: '90%', marginTop: '0px'}}/>
            {invoiceView === 'PrivateCustomerInfo' && <MemberInformation memberData = {memberData}/>}
            {invoiceView === 'PaymentInfo' && <MemberBankInfo memberData = {memberData}/>}
      </Layout>
    )
}

export default SpecificMemberDetails
