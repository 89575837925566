import React, { useState } from 'react';
import CompanyInformation from './CompanyInformation';
import CardInfo from './CardInfo';
import '../styles/AccountInfo.css'; // Make sure to import your CSS file
import AdminNavbar from './AdminNavbar';
import Layout from './Layout';

const AccountInfo = () => {
  const [view, setView] = useState('company-information'); // 'company-information' or 'card-information'
  const [button1Color, setButton1Color] = useState('#153243');
  const [button2Color, setButton2Color] = useState('#95B8D1');

  const handleCardInfo = () => {
    setView('card-information');
    setButton1Color('#95B8D1');
    setButton2Color('#153243');
  };

  const handleCompanyInfo = () => {
    setView('company-information');
    setButton1Color('#153243');
    setButton2Color('#95B8D1');
  };

  return (
    <Layout btnStatus='none' setMWidth='80%'>
        <div className="heading-div">
          <button
            style={{ backgroundColor: button1Color }}
            onClick={() => handleCompanyInfo()}
            className={`valuta-heading ${view === 'company-information' ? 'active' : ''}`}
          >
            Företagets uppgifter
          </button>
          <button
            style={{ backgroundColor: button2Color }}
            onClick={() => handleCardInfo()}
            id='btn-heading-2'
            className={`valuta-heading ${view === 'card-information' ? 'active' : ''}`}
          >
            Betalningssätt
          </button>
        </div>

        {view === 'company-information' && <CompanyInformation />}
        {view === 'card-information' && <CardInfo />}
    </Layout>
  );
};

export default AccountInfo;
