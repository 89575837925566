import React, {useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import "../styles/Signin.css";
import Spinner from "./Spinner";
const AdminSigninForm = ({ onForgotPasswordClick, displayStatus }) => {
    let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch("https://globalworker-backend.vercel.app/api/admin/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      if (response.ok) {
        localStorage.setItem("token", data.authtoken);
        navigate("/");
        console.log("Login successful.");
        console.log(data.authtoken);
        setLoading(false);
      } else {
        console.error("Login failed:", data.error);
        appendAlert(data.error, "danger");
        console.error("Login failed:", data.error);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error during login:", error.message);
      appendAlert("Error during login. Please try again.", "danger");
      console.error("Error during login:", error.message);
      setLoading(false);
    }
  };

  const alertPlaceholder = document.getElementById("liveAlertPlaceholder");
  const appendAlert = (message, type) => {
    const wrapper = document.createElement("div");
    wrapper.innerHTML = [
      `<div class="alert alert-${type} alert-dismissible" role="alert">`,
      `   <div>${message}</div>`,
      '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
      "</div>",
    ].join("");

    alertPlaceholder.append(wrapper);
  };
  return (
    <>
      <div id="liveAlertPlaceholder"></div>
      <form style={{width: '100%'}} className="mt-4" onSubmit={handleLogin}>
        <h3 id="login-heading">Logga in</h3>
        <div className="dropdown">
          <button
            className="btn btn-secondary dropdown-toggle"
            type="button"
            id="languageDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Svenska (Sverige)
          </button>
          <ul className="dropdown-menu" aria-labelledby="languageDropdown">
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => changeLanguage("Svenska (Sverige)")}
              >
                Svenska (Sverige)
              </a>
            </li>
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => changeLanguage("English")}
              >
                English
              </a>
            </li>
            {/* Add more language options as needed */}
          </ul>
        </div>
        <hr id="divider" />
        <label className="form-lebels" htmlFor="signin-email">
          E-post
        </label>
        <input
          type="email"
          id="signin-email"
          className="form-control mb-4"
          placeholder="example123@gmail.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <label className="form-lebels" htmlFor="signin-Password">
          Lösenord
        </label>
        <input
          type="password"
          id="signin-Password"
          className="form-control mb-2"
          placeholder="***********"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <div className="main-check-container">
          <div className="form-check-container">
            <input
              type="checkbox"
              id="autoSizingCheck2"
              className="form-check-input-field"
            />
            <label className="form-check-lab" htmlFor="autoSizingCheck2">
              Kom ihåg mig?
            </label>
          </div>
          <p>
            <Link onClick={onForgotPasswordClick} >
              Glömt ditt lösenord?
            </Link>
          </p>
        </div>
        <button type="submit" id="login-btn" className="btn btn-primary mt-3">
          Logga in
        </button>
        <div className='mt-2'>
        {loading === true && <Spinner/>}
        </div>
      </form>
    </>
  );
};

export default AdminSigninForm;
function changeLanguage(language) {
  document.getElementById("languageDropdown").innerText = language;
}
