import React, { useEffect, useState } from "react";

const MemberBankInfo = ({memberData}) => {
    const [selectedOption, setSelectedOption] = useState(
        memberData?.bankInfo?.bankAndWiseOption || ""
      );
      const [bankCountry, setBankCountry] = useState("");
      const [bankName, setBankName] = useState("");
      const [accountNumber, setAccountNumber] = useState("");
      const [iban, setIban] = useState("");
      const [swiftCode, setSwiftCode] = useState("");
      const [otherInfo, setOtherInfo] = useState("");
      const [paypalEmail, setPaypalEmail] = useState("");
    
      useEffect(() => {
        console.log(memberData);
        if (
          memberData?.bankInfo?.bankAndWiseOption === "bankAccountInfo" &&
          memberData?.bankInfo?.bankAccountInfo
        ) {
          setBankCountry(memberData?.bankInfo?.bankAccountInfo?.country);
          setBankName(memberData?.bankInfo?.bankAccountInfo?.bankName);
          setAccountNumber(memberData?.bankInfo?.bankAccountInfo?.accountNumber);
          setIban(memberData?.bankInfo?.bankAccountInfo?.ibanNumber);
          setSwiftCode(memberData?.bankInfo?.bankAccountInfo?.swiftCode);
          setOtherInfo(memberData?.bankInfo?.bankAccountInfo?.otherInfo);
        }
        if (
          memberData?.bankInfo?.bankAndWiseOption === "wiseAccountInfo" &&
          memberData?.bankInfo?.wiseAccountInfo
        ) {
          setBankCountry(memberData?.bankInfo?.wiseAccountInfo?.country);
          setBankName(memberData?.bankInfo?.wiseAccountInfo?.wiseBankName);
          setIban(memberData?.bankInfo?.wiseAccountInfo?.ibanNumber);
        }
        if (
          memberData?.bankInfo?.bankAndWiseOption === "paypalInfo" &&
          memberData?.bankInfo?.paypalInfo
        ) {
          setPaypalEmail(memberData?.bankInfo?.paypalInfo?.email);
        }
      }, [memberData]);
      return (
        <>
          <div className="client-info-main-container">
            <div className="client-info-radio-container bank-radio-btn-container">
              <h6 className="bank-radio-heading">
                Jag väljer att fakturera enligt ikryssat alternativ.
              </h6>
              <div className="Privatpersons-container bank-info-container">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    disabled
                    value="wiseAccountInfo"
                    checked={selectedOption === "wiseAccountInfo"}
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault1">
                    Alternativ 1 - Avgift 6%: Utbetalning till ett Wise konto.
                  </label>
                </div>
                <button className="bank-info-read-more-btn">Läs mer</button>
              </div>
    
              <div className="företags-kund-container bank-info-container">
                <div className="form-check">
                  <input
                    checked={selectedOption === "bankAccountInfo"}
                    value="bankAccountInfo"
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    disabled
                  />
    
                  <label className="form-check-label" htmlFor="flexRadioDefault2">
                    Alternativ 2 - Avgift 8%: Utbetalning till valfri bank.
                  </label>
                </div>
                <button className="bank-info-read-more-btn">Läs mer</button>
              </div>

              <div className="företags-kund-container bank-info-container">
                <div className="form-check">
                  <input
                    checked={selectedOption === "paypalInfo"}
                    value="paypalInfo"
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault3"
                    disabled
                  />
    
                  <label className="form-check-label" htmlFor="flexRadioDefault3">
                    Alternativ 3 - Avgift 10%: Utbetalning till PayPal.
                  </label>
                </div>
                <button className="bank-info-read-more-btn">Läs mer</button>
              </div>

              {selectedOption === 'wiseAccountInfo' && <><div className="">
                <label htmlFor="bankCountry" className="label form-label">
                  Bankens Land
                </label>
                <input
                  style={{ backgroundColor: "#dddddddd", width: "45%" }}
                  value={bankCountry}
                  type="text"
                  className="form-input form-control fl-bank-input-field"
                  id="bankCountry"
                  disabled
                />
              </div>
              <div className="">
                <label htmlFor="bankName" className="label form-label">
                  Bankens namn
                </label>
                <input
                  style={{ backgroundColor: "#dddddddd" }}
                  value={bankName}
                  type="text"
                  className="form-input form-control fl-bank-input-field"
                  id="bankName"
                  disabled
                />
              </div>
              <div className="">
                <label htmlFor="ibanNumber" className="label form-label">
                  IBAN-nummer
                </label>
                <input
                  style={{ backgroundColor: "#dddddddd" }}
                  value={iban}
                  type="text"
                  className="form-input form-control fl-bank-input-field"
                  id="ibanNumber"
                  disabled
                />
              </div></>}


              {selectedOption === 'bankAccountInfo' && <><div className="">
                <label htmlFor="bankCountry" className="label form-label">
                  Bankens Land
                </label>
                <input
                  style={{ backgroundColor: "#dddddddd", width: "45%" }}
                  value={bankCountry}
                  type="text"
                  className="form-input form-control fl-bank-input-field"
                  id="bankCountry"
                  disabled
                />
              </div>
              <div className="">
                <label htmlFor="bankName" className="label form-label">
                  Bankens namn
                </label>
                <input
                  style={{ backgroundColor: "#dddddddd" }}
                  value={bankName}
                  type="text"
                  className="form-input form-control fl-bank-input-field"
                  id="bankName"
                  disabled
                />
              </div>
              <div className="">
                <label htmlFor="bank-accNo-field" className="label form-label">
                  Ditt Kontonummer
                </label>
                <input
                  type="number"
                  value={accountNumber}
                  className="form-input form-control fl-bank-input-field"
                  id="bank-accNo-field"
                  maxLength={32}
                  style={{ backgroundColor: "#dddddddd" }}
                  disabled
                />
              </div>
              <div className="">
                <label htmlFor="ibanNumber" className="label form-label">
                  IBAN-nummer
                </label>
                <input
                  style={{ backgroundColor: "#dddddddd" }}
                  value={iban}
                  type="text"
                  className="form-input form-control fl-bank-input-field"
                  id="ibanNumber"
                  disabled
                />
              </div>
              <div className="">
                <label htmlFor="bank-swift-field" className="label form-label">
                  BIC/Swift-kode
                </label>
                <input
                  type="text"
                  value={swiftCode}
                  className="form-input form-control fl-bank-input-field"
                  id="bank-swift-field"
                  style={{ backgroundColor: "#dddddddd" }}
                  disabled
                />
              </div>
              <div className="">
                <label htmlFor="bank-otherInfo-field" className="label form-label">
                  Annan information
                </label>
                <textarea
                  rows="4"
                  cols="10"
                  placeholder="(Valfritt)"
                  value={otherInfo}
                  className="form-input form-control fl-bank-input-field"
                  id="bank-otherInfo-field"
                  style={{ backgroundColor: "#dddddddd" }}
                  disabled
                ></textarea>
              </div></>}
              {selectedOption === 'paypalInfo' && <>
                <div className="">
                <label htmlFor="paypalEmail" className="label form-label">
                  E-post
                </label>
                <input
                  style={{ backgroundColor: "#dddddddd" }}
                  value={paypalEmail}
                  type="text"
                  className="form-input form-control fl-bank-input-field"
                  id="paypalEmail"
                  disabled
                />
              </div></>
              }
            </div>
          </div>
        </>
      );
}

export default MemberBankInfo
