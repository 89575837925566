import React, {useEffect, useState} from 'react'

const MemberInformation = ({memberData}) => {

    
      useEffect(()=>{
        console.log(memberData?.userProfile?.privateUserInfo?.pvtFname)
      }, [memberData])
      return (
        <>
        <div className='client-info-main-container'>
        <div className='client-info-radio-container'>
        <div className='Privatpersons-container'>
          <h6 className='Privatpersons-heading'>Privatpersons eller</h6>
          <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    disabled
                    defaultChecked
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault1">
                    Privatperson
                  </label>
                </div>
            </div>
            </div>
          <form className="form-div row g-3">
                <div className="">
                  <label htmlFor="pvtFName" className="label form-label">
                        Förnamn
                  </label>
                  <input type="text" className="form-input form-control" id="pvtFName" disabled defaultValue={memberData?.userProfile?.privateUserInfo?.pvtFname || ""} />
                </div>
    
                <div className="">
                  <label htmlFor="pvtLName" className="label form-label">
                        Efternamn
                  </label>
                  <input value={memberData?.userProfile?.privateUserInfo?.pvtLname || ""} type="text" className="form-input form-control" id="pvtLName" disabled />
                </div>
                <div className="">
                  <label htmlFor="pvtEmail" className="label form-label">
                        E-post
                  </label>
                  <input value={memberData?.userProfile?.privateUserInfo?.pvtUserEmail || ""} type="email" className="form-input form-control" id="pvtEmail" disabled />
                </div>
                <div className="">
                  <label htmlFor="pvtTel" className="label form-label">
                  Telefon nummer
                  </label>
                  <input value={memberData?.userProfile?.privateUserInfo?.pvtTel || ""} type="text" className="form-input form-control" id="pvtTel" disabled />
                </div>
                <div className="">
                  <label htmlFor="pvtMobNo" className="label form-label">
                  Mobil nummer
                  </label>
                  <input value={memberData?.userProfile?.privateUserInfo?.pvtMobNo || ""} type="text" className="form-input form-control" id="pvtMobNo" disabled />
                </div>
                <div className="">
                  <label htmlFor="pvtSkypeName" className="label form-label">
                  Skype namn
                  </label>
                  <input value={memberData?.userProfile?.privateUserInfo?.pvtSkypeName || ""} type="text" className="form-input form-control" id="pvtSkypeName" disabled />
                </div>
            </form>
            </div>
        </>
      )
}

export default MemberInformation
