import React, { useState, useEffect } from "react";
import Layout from "./Layout";
import "../styles/Currency.css";
import Spinner from "./Spinner";

const CountryList = () => {
  const [countries, setCountries] = useState([
    { CountryName: "Sweden", CountryTwoLetterIsoCode: "SE", publish: true },
    { CountryName: "Cyprus", CountryTwoLetterIsoCode: "CY", publish: true },
    { CountryName: "Spain", CountryTwoLetterIsoCode: "ES", publish: true },
    { CountryName: "Malta", CountryTwoLetterIsoCode: "MT", publish: true },
    { CountryName: "Greece", CountryTwoLetterIsoCode: "GR", publish: true },
    { CountryName: "Portugal", CountryTwoLetterIsoCode: "PT", publish: true },
    { CountryName: "United Kingdom", CountryTwoLetterIsoCode: "GB", publish: true, },
    { CountryName: "United States", CountryTwoLetterIsoCode: "US", publish: true, },
    { CountryName: "Thailand", CountryTwoLetterIsoCode: "TH", publish: true },
    { CountryName: "Denmark", CountryTwoLetterIsoCode: "DK", publish: true },
    { CountryName: "Philippines", CountryTwoLetterIsoCode: "PH", publish: true },
  ]);
  const [savedCountries, setSavedCountries] = useState([]);

  const [ncountries, setnCountries] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const response = await fetch(
          "https://globalworker-backend.vercel.app/api/admin/countries/getcountries",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem("token"),
            },
          }
        );
        if (!response.ok) {
          console.log("Error while fetching countries");
        }
        const fetchedData = await response.json();
        console.log(fetchedData);
        setSavedCountries(fetchedData);

        const updatedCountries = countries.map((country) => {
          const matchingCountry = fetchedData.find(
            (fetchedCountry) =>
              fetchedCountry.countryName === country.CountryName
          );

          if (matchingCountry) {
            return {
              ...country,
              CountryName: matchingCountry.countryName,
              publish: matchingCountry.isPublish,
            };
          }

          return country;
        });

        setnCountries(updatedCountries);
        setCountries(updatedCountries);
        console.log(updatedCountries);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };
    getCountries();
  }, []);

  const handlePublishChange = (index, value) => {
    const updatedCountries = [...countries];
    updatedCountries[index].publish = value;
    setCountries(updatedCountries);
    console.log("Updated Countries:", updatedCountries);
  };

  const handleSubmitCountries = async (e) => {
    setBtnDisabled(true);
    setLoading(true);
    e.preventDefault();
    try {
      const newData = countries.map((country, index) => ({
        countryName: country.CountryName,
        isPublish: country.publish,
      }));
      console.log(newData);
      const response = await fetch(
        "https://globalworker-backend.vercel.app/api/admin/countries/savecountries",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("token"),
          },
          body: JSON.stringify({ countries: newData }),
        }
      );
      if (!response.ok) {
        throw new Error("Error while saving countries");
      }
      appendAlert("Countries saved successfully.", "success");
      console.log("data saved");
      setBtnDisabled(false);
      setLoading(false);
    } catch (error) {
      appendAlert("Error while saving countries.", "danger");
      console.log("error while saving data");
      setBtnDisabled(false);
      setLoading(false);
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const alertPlaceholder = document.getElementById("liveAlertPlaceholder");
  const appendAlert = (message, type) => {
    const wrapper = document.createElement("div");
    wrapper.innerHTML = [
      `<div class="alert alert-${type} alert-dismissible" role="alert">`,
      `   <div>${message}</div>`,
      '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
      "</div>",
    ].join("");

    alertPlaceholder.append(wrapper);
  };

  return (
    <>
    <Layout btnStatus={"none"} heading={""}>
      <div id="liveAlertPlaceholder" style={{ width: "100%" }}></div>
      <form onSubmit={handleSubmitCountries} className="table-div">
        <div className="row mb-3 row-1 mt-4 table-rows">
          <div className="col">Land</div>
          <div className="col">Land Två Bokstäver är Kod</div>
          <div className="col"></div>
        </div>

        {ncountries?.map((content, index) => (
          <div className="row table-rows" key={index}>
            {Object.keys(content).map((key, colIndex) => (
              <div className="col" key={colIndex}>
                {key === "publish" ? (
                  <select
                    defaultValue={content[key]}
                    onChange={(e) => handlePublishChange(index, e.target.value)}
                  >
                    <option value={true}>Sann</option>
                    <option value={false}>Falsk</option>
                  </select>
                ) : (
                  content[key]
                )}
              </div>
            ))}
          </div>
        ))}
        <div
          className="mt-4"
          style={{ width: "90%", display: "flex", justifyContent: "center" }}
        >
          <div className="sendBtn-div mb-3" style={{ width: "75%" }}>
            <button type="submit" className="send-mail-btn">
              Spara
            </button>
          </div>
        </div>
        <div
          disabled={btnDisabled}
          style={{ paddingLeft: "148px" }}
          className="sendBtn-div mb-3"
        >
          {loading && <Spinner />}
        </div>
      </form>
    </Layout>
    </>
  );
};

export default CountryList;
