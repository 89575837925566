import React, {useEffect, useState} from "react";
import "../styles/GeneratedInvoice.css";
import { useParams } from "react-router-dom";
const GeneratedDeletedInvoice = () => {
    const { invoiceId } = useParams();
    const [invoiceData, setInvoiceData] = useState([]);
    const [globalworkerBankInfo, setGlobalworkerBankInfo] = useState({});
    const [currentDate, setCurrentDate] = useState(new Date());
    const [percentageAmount, setPercentageAmount] = useState(0);
    const [interest, setInterest] = useState(0);
    const [totalAmountWithInterest, setTotalAmountWithInterest] = useState(0);
    const [user, setUser] = useState([])
    useEffect(()=>{
      document.title = 'Admin - Deleted Invoice'
      const getInvoicesData = async () =>{
        console.log(invoiceId)
        try {
          const response = await fetch(`https://globalworker-backend.vercel.app/api/invoice/admin/gettrashinvoice/${invoiceId}`,{
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
          })
          if(!response.ok){
            throw new Error("Error while fetching the invoices data");
          }
          const data = await response.json();
          setInvoiceData(data)

          const bankResponse = await fetch(`https://globalworker-backend.vercel.app/api/admin/bankinfo/getadminbankinfo`,{
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
          })
  
          if(!bankResponse.ok){
            throw new Error("Error while fetching the bank data");
          }
  
          const adminBankData = await bankResponse.json();
          setGlobalworkerBankInfo(adminBankData)
          console.log(adminBankData)
  
          if (data.userBankInfo) {
            if (data.userBankInfo.bankAndWiseOption === 'bankAccountInfo') {
              setInterest(8);
              let amount = parseFloat(data.invoice.totalAmount) * 1.08;
              let increaseAmount = amount - data.invoice.totalAmount; // Calculate the increase
              setTotalAmountWithInterest(amount);
              setPercentageAmount(increaseAmount);
            } else if (data.userBankInfo.bankAndWiseOption === 'wiseAccountInfo') {
              setInterest(6);
              let amount = parseFloat(data.invoice.totalAmount) * 1.06;
              let increaseAmount = amount - data.invoice.totalAmount; // Calculate the increase
              setTotalAmountWithInterest(amount);
              setPercentageAmount(increaseAmount);
            }
          }
  
          const userId = data.invoice.user;
          console.log(userId)
  
          const userResponse = await fetch(`https://globalworker-backend.vercel.app/api/auth/admin/getfreelanceruser/${userId}`,{
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('token'),
            },
          })
          if(!response.ok){
            throw new Error("Error while fetching the user data");
          }
          const userData = await userResponse.json();
          console.log(userData)
          setUser(userData)
  
        } catch (error) {
          console.log("Error while fetching the invoices data");
        }
      }
      getInvoicesData()
    }, [])
  
  
    return (
      <div id='generatedInvoice' className="generatedInvoiceContainer">
        <div className="g-invoiceHeader mb-4 mt-5">
          <img src={require("../assets/hela@10x-100.png")} alt="noimgae" />
          <table>
          <tbody>
            <tr>
              <th>Date</th>
              <th>Customer</th>
            </tr>
            <tr>
              <td>{currentDate.toLocaleDateString()}</td>
              <td>{invoiceData?.clientData?.customerNumber}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className="invoiceCustomerDetails mb-4 mt-2">
          <table className="invoiceCustomerDetailsTable" >
            <tbody>
            <tr>
              <th>Our Reference:</th>
              <td>{invoiceData?.invoice?.clientName}</td>
            </tr>
            <tr>
              <td>{invoiceData?.userData?.profileOption === 'private' ? invoiceData?.userData?.privateUserInfo?.pvtFname : invoiceData?.userData?.organizationUserInfo?.orgFname} {invoiceData?.userData?.profileOption === 'private' ? invoiceData?.userData?.privateUserInfo?.pvtLname : invoiceData?.userData?.organizationUserInfo?.orgLname}</td>
              <td>{invoiceData?.clientData?.clientOption === "private"
                  ? invoiceData?.clientData?.privateClient?.pvtStreet
                  : invoiceData?.clientData?.organizationClient?.orgStreet}
              </td>
            </tr>
            <tr>
              <td>{user.email}</td>
              <td>{`${invoiceData?.clientData?.clientOption === "private"
                    ? invoiceData?.clientData?.privateClient?.pvtPostalCode
                    : invoiceData?.clientData?.organizationClient?.orgPostalCode} 
                    ${invoiceData?.clientData?.clientOption === "private"
                    ? invoiceData?.clientData?.privateClient?.pvtCity
                    : invoiceData?.clientData?.organizationClient?.orgCity}`}</td>
            </tr>
            <tr>
              <th>Your Reference:</th>
              <td>{invoiceData?.clientData?.clientOption === "private"
                    ? invoiceData?.clientData?.privateClient?.pvtCountry
                    : invoiceData?.clientData?.organizationClient?.orgCountry}
              </td>
            </tr>
            <tr>
              <td>{invoiceData?.clientData?.clientOption === "private"
                    ? invoiceData?.clientData?.privateClient?.pvtReferenceName
                    : invoiceData?.clientData?.organizationClient?.orgReferenceName}
              </td>
              <td></td>
            </tr>
            <tr>
              <th>Customer Email:</th>
              <td>{invoiceData?.clientData?.clientOption === "private"
                    ? invoiceData?.clientData?.privateClient?.pvtRecipientEmail
                    : invoiceData?.clientData?.organizationClient?.orgRecipientEmail}
              </td>
            </tr>
            <tr>
              <th>Payment terms:</th>
              <td>{invoiceData?.invoice?.noOfDueDates}</td>
            </tr>
            <tr>
              <th>Due Date:</th>
              <td>{invoiceData?.invoice?.dueDate}</td>
            </tr>
            <tr>
              <th>Interest:</th>
              <td>{`${interest}%`}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className="invoiceAmountContainer mb-4">
          <table className="invoiceAmountTable">
            <tbody>
            <tr>
              <th>Work Description</th>
              <th>Quantity</th>
              <th>Unit</th>
              <th>Price</th>
              <th>Amount</th>
            </tr>
              {invoiceData?.invoiceAmount?.map((data, index) => (
                <tr key={index}>
                  <td >{data.workDescription}</td>
                  <td >{data.quantity}</td>
                  <td >{data.unit}</td>
                  <td >{data.fixedPrice == null ? data.price : data.fixedPrice}</td>
                  <td >{data.totalAmount}</td>
                </tr>
              ))}
            <tr>
              <th style={{backgroundColor: '#86e3e34f'}} colSpan="4">Total</th>
              <th>{invoiceData?.invoice?.totalAmount.toFixed(2)}</th>
            </tr>
            </tbody>
          </table>
        </div>
        <div className="invoice-note-container mb-4">
          <p>
            Note: Please add the invoice number: {invoiceData?.invoice?.invoiceNumber} in the reference section
            of your online payment
          </p>
          <p>We only accept EUR and SEK</p>
        </div>
        <div className="row invoice-bankInfo-container mb-4">
          <div className="col">
            <h5>URL</h5>
            <p className="mb-4">Globalworker.se</p>
            <h5>Mail</h5>
            <p>info@globalworker.se</p>
          </div>
          <div className="col">
            <table className="invoice-bankInfo-table">
              <tbody>
              <tr>
                <th>BIC/SWIFT CODE</th>
                <td>{globalworkerBankInfo?.ibanInformation?.swiftCode}</td>
              </tr>
              <tr>
                <th>IBAN</th>
                <td>{globalworkerBankInfo?.ibanInformation?.iban}</td>
              </tr>
              <tr>
                <th>Bank Name</th>
                <td>{globalworkerBankInfo?.ibanInformation?.ibanBankName}</td>
              </tr>
              <tr>
                <th>City</th>
                <td>{globalworkerBankInfo?.ibanInformation?.city}</td>
              </tr>
              <tr>
                <th>Country</th>
                <td>{globalworkerBankInfo?.ibanInformation?.country}</td>
              </tr>
            </tbody>
            </table>
          </div>
        </div>
        <footer className="mt-5" style={{ width: "80%" }}>
          <p>GLOBAL FREELANCER WORKERS LTD</p>
          <p>STRON LEGAL CLUBHOUSE ST JAMES 8</p>
          <p>SW1Y 4JU, United Kingdom, Company No. 14534594</p>
        </footer>
      </div>
    );
}

export default GeneratedDeletedInvoice