import React, { useContext, useEffect, useState } from 'react';
import '../styles/CompanyInformation.css'
import '../styles/Layout.css' 
import adminContext from '../context/AdminContext';

const CompanyInformation = () => {
  const context = useContext(adminContext);
  const {saveAdminProfile} = context;
  
  const initialCompanyInfo = {
        companyName: '',
        companyEmail: '',
        organizationNumber: '',
        VATNumber: '',
        companyCountry: '',
        address: '',
        zipCode: '',
        postalAddress: '',
        website: '',
        country: '',
    }
  
    const initialContactPerson = {
        firstName: '',
        lastName: '',
        email: '',
        mobileNo: '',
        telNo: '',
    }
  const [companyInfo, setCompanyInfo] = useState(initialCompanyInfo);
  const [contactPersonInfo, setContactPersonInfo] = useState(initialContactPerson);

  useEffect(()=>{
    const getAdminProfile = async () =>{
      try {
        const response = await fetch("https://globalworker-backend.vercel.app/api/admin/getadminprofile", {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem('token'),
          },
        })
        if(!response.ok){
          throw new Error("Error while fetching the admin profile");
        }
        const data = await response.json();
        console.log(data)
        if(data){
          console.log(data.companyInformation)
          console.log(data.contactPerson)
          setCompanyInfo(data.companyInformation);
          setContactPersonInfo(data.contactPerson);
        }
      } catch (error) {
        console.log("Error. Please try again later");
      }
    }
    getAdminProfile();
  }, [])

  const handleCompanyInfo = (e) => {
    setCompanyInfo((prev) => {
      let helper = { ...prev };
      helper[`${e.target.id}`] = e.target.value;
      return helper; 
    });
  };
  const handleContactInfo = (e) => {
    setContactPersonInfo((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const handleSave = async (e) =>{
    e.preventDefault();
    try {
      const success = await saveAdminProfile({companyInformation: companyInfo, contactPerson: contactPersonInfo});
      if(success){
        console.log("Data Saved Successfully")
        appendAlert("Data Saved Successfully.", "success");
      }
    } catch (error) {
      console.log("failed to save data");
      appendAlert("Please try again later.", "danger");
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

    const alertPlaceholder = document.getElementById('liveAlertPlaceholder')
    const appendAlert = (message, type) => {
    const wrapper = document.createElement('div')
    wrapper.innerHTML = [
      `<div class="alert alert-${type} alert-dismissible" role="alert">`,
      `   <div>${message}</div>`,
      '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
      '</div>'
    ].join('')
  
    alertPlaceholder.append(wrapper)
  }
  
  return (
    
    <form onSubmit={handleSave} className='companyInfo-form'>
      <div id="liveAlertPlaceholder" style={{width: '100%'}}></div>
      <div className="row-1 company-info-row1">
        <div className="col">
          <h6 className="form-heading">Lägg till Logotyp</h6>
          <div className="form-div row g-3">
            <div className="">
              <label htmlFor="companyName" className="label form-label">
                Företagets namn
              </label>
              <input  onChange={handleCompanyInfo} value={companyInfo.companyName} type="text" className="form-input form-control" id="companyName" />
            </div>

            <div className="">
              <label htmlFor="companyEmail" className="label form-label">
                Företagets e-post
              </label>
              <input  onChange={handleCompanyInfo} value={companyInfo.companyEmail} type="email" className="form-input form-control" id="companyEmail" />
            </div>
            <div className="">
              <label htmlFor="organizationNumber" className="label form-label">
                Organisationsnummer
              </label>
              <input  onChange={handleCompanyInfo} value={companyInfo.organizationNumber} type="number" className="form-input form-control" id="organizationNumber" />
            </div>
            <div className="">
              <label htmlFor="VATNumber" className=" label form-label">
                Momsregistreringsnummer
              </label>
              <input  onChange={handleCompanyInfo} value={companyInfo.VATNumber} type="text" className="form-input form-control" id="VATNumber" />
            </div>
            <div className="">
              <label htmlFor="companyCountry" className=" label form-label">
                Land
              </label>
              <input  onChange={handleCompanyInfo} value={companyInfo.companyCountry} type="text" className="form-input form-control" id="companyCountry" />
            </div>
          </div>
        </div>
        <div className="col">
          <h6 className="form-heading">Adress</h6>
          <div className="form-div row g-3">
            <div className="">
              <label htmlFor="address" className="label form-label">
                Adress 1
              </label>
              <input  onChange={handleCompanyInfo} value={companyInfo.address} type="text" className="form-input form-control" id="address" />
            </div>

            <div className="">
              <label htmlFor="zipCode" className="label form-label">
                Postnummer
              </label>
              <input  onChange={handleCompanyInfo} value={companyInfo.zipCode} type="number" className="form-input form-control" id="zipCode" />
            </div>
            <div className="">
              <label htmlFor="postalAddress" className="label form-label">
                Postorts
              </label>
              <input  onChange={handleCompanyInfo} value={companyInfo.postalAddress} type="text" className="form-input form-control" id="postalAddress" />
            </div>
            <div className="">
              <label htmlFor="website" className=" label form-label">
                Hemsida
              </label>
              <input  onChange={handleCompanyInfo} value={companyInfo.website} type="text" className="form-input form-control" id="website" />
            </div>
            <div className="">
              <label htmlFor="country" className=" label form-label">
                Land
              </label>
              <input  onChange={handleCompanyInfo} value={companyInfo.country} type="text" className="form-input form-control" id="country" />
            </div>
          </div>
        </div>
      </div>

      <hr style={{ width: '80%' }} />

      <h6 className="contact-person-heading">KONTAKTPERSON</h6>
      <div className="row-2 company-info-row2">
        <div className="col">
          <div className="form-div row g-3">
            <div className="">
              <label htmlFor="firstName" className="label form-label">
                Förnamn
              </label>
              <input  onChange={handleContactInfo} value={contactPersonInfo.firstName} type="text" className="form-input form-control" id="firstName" />
            </div>

            <div className="">
              <label htmlFor="lastName" className="label form-label">
                Efternamn
              </label>
              <input  onChange={handleContactInfo} value={contactPersonInfo.lastName} type="text" className="form-input form-control" id="lastName" />
            </div>
          </div>
        </div>
        <div className="col">
          <div className="form-div row g-3">
            <div className="">
              <label htmlFor="email" className="label form-label">
                E-post
              </label>
              <input  onChange={handleContactInfo} value={contactPersonInfo.email} type="email" className="form-input form-control" id="email" />
            </div>

            <div className="">
              <label htmlFor="mobileNo" className="label form-label">
                Mobiltelefon
              </label>
              <input  onChange={handleContactInfo} value={contactPersonInfo.mobileNo} type="number" className="form-input form-control" id="mobileNo" />
            </div>

            <div className="">
              <label htmlFor="telNo" className="label form-label">
                Telefon
              </label>
              <input  onChange={handleContactInfo} value={contactPersonInfo.telNo} type="number" className="form-input form-control" id="telNo" />
            </div>
          </div>
        </div>
      </div>
      <div className='save-companyInfo-div mb-3'>
        <button type='submit'>Spara</button>
      </div>
    </form>
  );
};

export default CompanyInformation;
