import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Currency from './components/Currency';
import AccountInfo from './components/AccountInfo';
import CountryList from './components/CountryList';
import Invoice from './components/Invoice';
import InvoiceView from './components/InvoiceView';
import Members from './components/Members';
import AdminSignin from './components/AdminSignin'
import Footer from './components/Footer'
import AdminProvider from './context/AdminProvider';
import ClientGeneratedInvoice from './components/ClientGeneratedInvoice';
import SalaryInvoice from './components/SalaryInvoice';
import CreditInvoice from './components/CreditInvoice';
import InvoiceTrash from './components/InvoiceTrash';
import GeneratedDeletedInvoice from './components/GeneratedDeletedInvoice';
import ResetPassword from './components/ResetPassword';
import AuthRoute from './components/AuthRoute';
import AuthCheck from './components/AuthCheck';
import SpecificMemberDetails from './components/SpecificMemberDetails';
import AdminMailBox from './components/AdminMailBox';
import MemberInvoices from './components/MemberInvoices';

function App() {
  const AuthenticatedAdminDashboard = AuthRoute(Dashboard);
  const AuthenticatedAdminCurrency = AuthRoute(Currency);
  const AuthenticatedAdminAccountInfo = AuthRoute(AccountInfo);
  const AuthenticatedAdminCountryList = AuthRoute(CountryList);
  const AuthenticatedAdminInvoice = AuthRoute(Invoice);
  const AuthenticatedAdminInvoiceTrash = AuthRoute(InvoiceTrash);
  const AuthenticatedAdminInvoiceView = AuthRoute(InvoiceView);
  const AuthenticatedAdminMembers = AuthRoute(Members);
  const AuthenticatedAdminClientGeneratedInvoice = AuthRoute(ClientGeneratedInvoice);
  const AuthenticatedAdminGeneratedDeletedInvoice = AuthRoute(GeneratedDeletedInvoice);
  const AuthenticatedAdminSalaryInvoice = AuthRoute(SalaryInvoice);
  const AuthenticatedAdminCreditInvoice = AuthRoute(CreditInvoice);
  const AuthenticatedAdminSignin = AuthCheck(AdminSignin);
  const AuthenticatedSpecificMemberDetails = AuthCheck(SpecificMemberDetails);
  const AuthenticatedAdminMailBox = AuthCheck(AdminMailBox);
  const AuthenticatedMemberInvoices = AuthCheck(MemberInvoices);

  return (
    <AdminProvider>
      <Router>
        <Routes>
          <Route exact path="/signin" element={<AuthenticatedAdminSignin/>} ></Route>

          <Route exact path="/" element={<AuthenticatedAdminDashboard/>} ></Route>
          <Route exact path="/currency" element={<AuthenticatedAdminCurrency/>} ></Route>
          <Route exact path="/account-information" element={<AuthenticatedAdminAccountInfo/>} ></Route>
          <Route exact path="/country-list" element={<AuthenticatedAdminCountryList/>} ></Route>
          <Route exact path="/invoice" element={<AuthenticatedAdminInvoice/>} ></Route>
          <Route exact path="/invoicetrash" element={<AuthenticatedAdminInvoiceTrash/>} ></Route>
          <Route exact path="/invoice/invoice-info/:invoiceId" element={<AuthenticatedAdminInvoiceView/>} ></Route>
          <Route exact path="/members/member-info/:memberId" element={<AuthenticatedSpecificMemberDetails/>} ></Route>
          <Route exact path="/members" element={<AuthenticatedAdminMembers/>} ></Route>
          <Route exact path="/admin/generated-invoice/:invoiceId" element={<AuthenticatedAdminClientGeneratedInvoice/>} ></Route>
          <Route exact path="/admin/generated-trash-invoice/:invoiceId" element={<AuthenticatedAdminGeneratedDeletedInvoice/>} ></Route>
          <Route exact path="/admin/generated-salary-invoice/:invoiceId" element={<AuthenticatedAdminSalaryInvoice/>} ></Route>
          <Route exact path="/admin/credit-invoice/:invoiceId" element={<AuthenticatedAdminCreditInvoice/>} ></Route>
          <Route exact path="/admin/member/mailbox/:memberId" element={<AuthenticatedAdminMailBox/>} ></Route>
          <Route exact path="/admin/member/memberInvoices/:memberId" element={<AuthenticatedMemberInvoices/>} ></Route>
          
          <Route exact path="/admin/resetpassword/:token" element={<ResetPassword/>} ></Route>
        </Routes>
        <Footer/>
      </Router>
    </AdminProvider>
  );
}

export default App;
