import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const AuthRoute = (Component) => {
  const AuthenticatedComponent = (props) => {
    const isAuthenticated = localStorage.getItem('token');
    const location = useLocation();
    if (!isAuthenticated) {
      // Redirect to the login page if not authenticated
      return <Navigate to="/signin" />;
    }

    if(location.pathname === '/'){
      document.title = 'Admin - Dashboard'
    }
    else if(location.pathname === '/currency'){
      document.title = 'Admin - Currency'
    }
    else if(location.pathname === '/account-information'){
      document.title = 'Admin - Account Information'
    }
    else if(location.pathname === '/country-list'){
      document.title = 'Admin - Countries'
    }
    else if(location.pathname === '/invoice'){
      document.title = 'Admin - invoices'
    }
    else if(location.pathname === '/invoicetrash'){
      document.title = 'Admin - Trash'
    }
    else if(location.pathname === '/members'){
      document.title = 'Admin - Members'
    }

    // Render the protected component if authenticated
    return <Component {...props} />;
  };

  return AuthenticatedComponent;
};

export default AuthRoute;