import React, { useState, useEffect } from "react";
import Layout from './Layout'
import '../styles/Currency.css';
import Spinner from "./Spinner";

const Currency = () => {
const [currencies, setCurrencies] = useState([
  { currencyName: 'Kronor', valutakod: 'SEK', symbol: 'kr', fee: 0, publish: true, },
  { currencyName: 'Euros', valutakod: 'EUR', symbol: '€', fee: 0, publish: true, },
  { currencyName: 'US Dollar', valutakod: 'USD', symbol: '$', fee: 0, publish: false, },
  { currencyName: 'Pounds', valutakod: 'GBP', symbol: '£', fee: 0, publish: false, },
  { currencyName: 'Baht', valutakod: 'THB', symbol: '?', fee: 0, publish: false, },
  { currencyName: 'Australian Dollar', valutakod: 'AUD', symbol: '$', fee: 0, publish: false, },
  { currencyName: 'Canadian dollar', valutakod: 'CAD', symbol: '$', fee: 0, publish: false, },
  { currencyName: 'Switzerland Francs', valutakod: 'CHF', symbol: 'CHF', fee: 0, publish: false, },
  { currencyName: 'Pesos', valutakod: 'CLP', symbol: '$', fee: 0, publish: false, },
  { currencyName: 'Yuan Renminbl', valutakod: 'CNY', symbol: '¥', fee: 0, publish: false, },
  { currencyName: 'Philippine peso', valutakod: 'PHP', symbol: '₱', fee: 0, publish: false, },
]);

const [ncurrencies, setnCurrencies] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getCurrencies = async () => {
      try {
        const response = await fetch(
          "https://globalworker-backend.vercel.app/api/admin/countries/getcurrency",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem("token"),
            },
          }
        );
        if (!response.ok) {
          console.log("Error while fetching countries");
        }
        const fetchedData = await response.json();
        console.log(fetchedData);

        const updatedCurrencies = currencies.map((currency) => {
          const matchingCurrencies = fetchedData.find(
            (fetchedCurrency) =>
            fetchedCurrency.currencyName === currency.currencyName
          );

          if (matchingCurrencies) {
            return {
              ...currency,
              currencyName: matchingCurrencies.currencyName,
              publish: matchingCurrencies.isPublish,
            };
          }

          return currency;
        });

        setnCurrencies(updatedCurrencies);
        setCurrencies(updatedCurrencies);
        console.log(updatedCurrencies);
      } catch (error) {
        console.error("Error fetching currencies:", error);
      }
    };
    getCurrencies();
  }, []);

  const handlePublishChange = (index, value) => {
    const updatedCountries = [...currencies];
    updatedCountries[index].publish = value;
    setCurrencies(updatedCountries);
    console.log("Updated Countries:", updatedCountries);
  };

  const handleSubmitCurrencies = async (e) => {
    setBtnDisabled(true);
    setLoading(true);
    e.preventDefault();
    try {
      const newData = currencies.map((currency, index) => ({
        currencyName: currency.currencyName,
        isPublish: currency.publish,
      }));
      console.log(newData);
      const response = await fetch(
        "https://globalworker-backend.vercel.app/api/admin/countries/savecurrency",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("token"),
          },
          body: JSON.stringify({ currencies: newData }),
        }
      );
      if (!response.ok) {
        throw new Error("Error while saving currencies");
      }
      appendAlert("currencies saved successfully.", "success");
      console.log("data saved");
      setBtnDisabled(false);
      setLoading(false);
    } catch (error) {
      appendAlert("Error while saving currencies.", "danger");
      console.log("error while saving data");
      setBtnDisabled(false);
      setLoading(false);
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const alertPlaceholder = document.getElementById("liveAlertPlaceholder");
  const appendAlert = (message, type) => {
    const wrapper = document.createElement("div");
    wrapper.innerHTML = [
      `<div class="alert alert-${type} alert-dismissible" role="alert">`,
      `   <div>${message}</div>`,
      '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
      "</div>",
    ].join("");

    alertPlaceholder.append(wrapper);
  };


  return (
    <Layout heading={"Valuta"}>
       <div id="liveAlertPlaceholder" style={{ width: "100%" }}></div>
      <form onSubmit={handleSubmitCurrencies} className='table-div'>
      <div className="row mb-3 row-1 table-rows">
        <div className="col">Valuta</div>
        <div className="col">Valuta kod</div>
        <div className="col">Valutasymbol</div>
        <div className="col">Fakturaavgift</div>
        <div className="col"></div>
      </div>

      {ncurrencies?.map((content, index) => (
          <div className="row table-rows" key={index}>
            {Object.keys(content).map((key, colIndex) => (
              <div className="col" key={colIndex}>
                {key === "publish" ? (
                  <select
                    defaultValue={content[key]}
                    onChange={(e) => handlePublishChange(index, e.target.value)}
                  >
                    <option value={true}>Sann</option>
                    <option value={false}>Falsk</option>
                  </select>
                ) : (
                  content[key]
                )}
              </div>
            ))}
          </div>
        ))}
        <div
          className="mt-4"
          style={{ width: "90%", display: "flex", justifyContent: "center" }}
        >
          <div className="sendBtn-div mb-3" style={{ width: "95%" }}>
            <button type="submit" className="send-mail-btn">
              Spara
            </button>
          </div>
        </div>
        <div
          disabled={btnDisabled}
          style={{ paddingLeft: "148px" }}
          className="sendBtn-div mb-3"
        >
          {loading && <Spinner />}
        </div>
</form>
    </Layout>
  );
};

export default Currency;
