import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import "../styles/MailBox.css";
import { useParams } from "react-router-dom";
import Spinner from './Spinner';
const AdminMailBox = () => {
    const {memberId} = useParams();
    const [memberEmail, setMemberEmail] = useState("");
    const [emailSubject, setEmailSubject] = useState("");
    const [emailText, setEmailText] = useState("");
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        document.title = 'Admin - Skicka e-post'
        const getMemberData = async () =>{
          try {
            const response = await fetch(`https://globalworker-backend.vercel.app/api/admin/members/memberdata/${memberId}`,{
              method: 'GET',
              headers: {
                "Content-Type": "application/json",
                "auth-token": localStorage.getItem('token'),
              },
            })
            if(!response.ok){
              console.log("Error while fetching the member data");
            }
            const data = await response.json();
            console.log(data);
            console.log(data.userProfile.privateUserInfo.pvtUserEmail);
            setMemberEmail(data.userProfile.privateUserInfo.pvtUserEmail);
         
          } catch (error) {
            console.log("Error while fetching the member data");
          }
        }
        getMemberData()
      }, [])

    const handleTextOnChange = (e) =>{
        setEmailText(e.target.value)
    }
    const handleSubjectOnChange = (e) =>{
        setEmailSubject(e.target.value)
    }

    const sendCustomEmail = async (e) =>{
        e.preventDefault();
        setBtnDisabled(true);
        setLoading(true);
        try {
            const response = await fetch(
              "https://globalworker-backend.vercel.app/api/admin/members/send-custom-mail",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "auth-token": localStorage.getItem('token'),
                },
                body: JSON.stringify({memberEmail: memberEmail, emailSubject: emailSubject, emailText: emailText}),
              }
            );
            if(!response.ok){
              console.log("Error while fetching data");
            }
            const data = await response.json();
            console.log(data);
            setBtnDisabled(false);
            setLoading(false);
            setEmailText("")
            setEmailSubject("")
            appendAlert("Email has been sent.", "success");
        } catch (error) {
            setLoading(false);
            appendAlert("Error while sendin email.", "danger");
            setBtnDisabled(false);
            console.log("Error while fetching data");
        }
    }

    const alertPlaceholder = document.getElementById("liveAlertPlaceholder");
  const appendAlert = (message, type) => {
    const wrapper = document.createElement("div");
    wrapper.innerHTML = [
      `<div class="alert alert-${type} alert-dismissible" role="alert">`,
      `   <div>${message}</div>`,
      '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
      "</div>",
    ].join("");

    alertPlaceholder.append(wrapper);
  };

  return (
    <Layout setMWidth={"90%"} btnStatus={"none"}>
    <div id="liveAlertPlaceholder" style={{width: '100%'}}></div>
      <div
        className="heading-div bank-info-heading-div"
        style={{ width: "100%", marginLeft: "70px" }}
      >
        <h5 className="valuta-heading bank-info-heading">Skicka e-post</h5>
      </div>
      <form onSubmit={sendCustomEmail} className="mail-main-container">
        <div className="mb-3 field-div">
          <label htmlFor="member-email" className="form-label">
            E-post
          </label>
          <input
            style={{width: '35%'}}
            type="email"
            className="form-control input-field"
            id="member-email"
            value={memberEmail}
            disabled
          />
        </div>
        <div className="mb-3 field-div">
          <label htmlFor="mail-subject" className="form-label">
            Ämne
          </label>
          <textarea onChange={handleSubjectOnChange} value={emailSubject} style={{width: '35%'}} name="mail-subject" className="form-control input-field" id="mail-subject" rows="1"></textarea>
        </div>
        <div className="mb-3 field-div">
          <textarea
            onChange={handleTextOnChange}
            value={emailText}
            placeholder="Textmeddelande"
            className="form-control input-field"
            id="mail-text-area"
            rows="10"
            style={{width: '60%'}}
          ></textarea>
        </div>
        <div className="sendBtn-div mb-3">
            <button type="submit" className="send-mail-btn">Skicka</button>
        </div>
        <div disabled={btnDisabled} style={{paddingLeft: '35px'}} className="sendBtn-div mb-3">
            {loading && <Spinner/>}
        </div>
      </form>
    </Layout>
  );
};

export default AdminMailBox;
